import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import ls from "localstorage-slim";
import * as DOMPurify from "dompurify";
import { getEncoding } from "js-tiktoken";
import {
  ignite_url,
  integration_name,
  front_plugin_url,
  apiCall,
  chat_url,
} from "../../constants/variables.js";

import fetchDraft from "../../../../plugin-apis/fetchDraft.js";
import {
  maxPromptTokens,
  maxModelTokens,
  isDebugMode,
} from "../../constants/variables.js";
import { END_POINTS } from "../../constants/endpoints.js";
import createDraft from "../../../../plugin-apis/createDraft.js";
import updateDraft from "../../../../plugin-apis/updateDraft.js";
import listMessages from "../../../../plugin-apis/listMessages.js";
import createComment from "../../../../plugin-apis/createComment.js";
import showErrorMessage from "../../utils/functions/showErrorMessage.js";
import getTextFromHTMLstr from "../../utils/functions/getTextFromHTMLstr.js";
import useListOfMessages from "../../../../utils/hooks/useListOfMessages.js";
import useFullMessageThread from "../../../../utils/hooks/useFullMessageThread.js";
import usePrimitiveFunctions from "../../services/server/usePrimitiveFunctions.js";
import extractLastMessageFromHTMLBody from "../../../../utils/functions/extractLastMessageFromHTMLBody";
import usePostProcessMessage from "../../utils/hooks/usePostProcessMessage.js";
import {
  conversationCounts,
  conversationTypes,
  isZendesk,
  messageType,
  summarizeSelectedValue,
} from "../../../../constants/plugin_variables";
import encryptData from "../../utils/functions/encryptData.js";
import decryptData from "../../utils/functions/decryptData.js";
import { usePluginContext } from "../../../../providers/contextManagement.js";
import {
  getResponseAgents,
  getResponseAssist,
  postResponseAssistApiCall,
  postResponseAssistChatbot,
  postResponseAssistChatbotAutoResponse,
  postResponseAssistEmail,
  postResponseAssistEmailAutoResponse,
  postResponseAssistQuery,
  postResponseAssistWebSearch,
} from "../../services/server/response-assist.service.js";
import { getCustomConfig } from "../../services/server/thirdparty.service.js";
import clearFirstWord from "../functions/clearFirstWord.js";
import removeLettersAfterWord from "../functions/removeLettersAfterWord.js";
import useSWR from "swr";
import { getExtractorsBriefly } from "../../api/extracor/index.js";
import useAttachmentsSWR from "./useAttachmentsSWR.js";
import { logoutUser } from "../functions/logoutUser.js";
export const resourceTypeTemp = [
  {
    id: "All My Resources",
    title: "All My Resources",
    text: "All My Resources",
  },
  ...(localStorage.getItem("isGoogleChecked") &&
  JSON.parse(localStorage.getItem("isGoogleChecked"))
    ? [
        {
          id: "DuckDuckGo",
          title: "Search Engine -  DuckDuckGo",
          text: "DuckDuckGo",
        },
      ]
    : ""),
  ...(localStorage.getItem("isGoogleChecked") &&
  JSON.parse(localStorage.getItem("isGoogleChecked"))
    ? [
        {
          id: "Google",
          title: "Search Engine - Google",
          text: "Google",
        },
      ]
    : ""),
];

const useChat = () => {
  const pluginContextData = usePluginContext();
  const context = useMemo(
    () => pluginContextData || JSON.parse(localStorage.getItem("context")),
    [localStorage.getItem("context"), pluginContextData]
  );

  const getAllResourcesAndFindTypes = async () => {
    await getResponseAgentsResult();
    if (
      localStorage.getItem("agents_list") &&
      JSON.parse(localStorage.getItem("agents_list")).length
    ) {
      const { resourceList = [], customResourceList = [] } =
        await getResponseAgentsResult("search");
      const resources = [
        ...resourceTypeTemp,
        ...resourceList,
        ...customResourceList,
      ];
      const apis = resources?.filter(
        (resource) =>
          resource?.appType?.toLowerCase() === "api" ||
          resource?.info?.appType?.toLowerCase() === "api"
      );
      const workflows = resources?.filter(
        (resource) =>
          resource?.appType?.toLowerCase() === "workflow" ||
          resource?.info?.appType?.toLowerCase() === "workflow"
      );
      const templates = resources?.filter((template) =>
        template?.source?.toLowerCase().includes("template")
      );

      const filteredResources = resources?.filter(
        (resource) =>
          !(
            resource?.appType?.toLowerCase() === "api" ||
            resource?.info?.appType?.toLowerCase() === "api"
          ) &&
          !(
            resource?.appType?.toLowerCase() === "workflow" ||
            !resource?.info?.appType?.toLowerCase() === "workflow"
          ) &&
          !resource?.source?.toLowerCase().includes("template")
      );

      return {
        resources: filteredResources,
        workflows,
        apis,
        templates: [{ id: "noOption", title: "No Template" }, ...templates],
      };
    } else {
      const { resourceList } = await getResponseAssistResult();
      const { customResourceList } = await getCustomConfigResult("search");

      const resources = [
        ...resourceTypeTemp,
        ...resourceList,
        ...customResourceList,
      ];
      const apis = resources?.filter(
        (resource) =>
          resource?.appType?.toLowerCase() === "api" ||
          resource?.info?.appType?.toLowerCase() === "api"
      );
      const workflows = resources?.filter(
        (resource) =>
          resource?.appType?.toLowerCase() === "workflow" ||
          resource?.info?.appType?.toLowerCase() === "workflow"
      );
      const templates = resources?.filter((template) =>
        template?.source?.toLowerCase().includes("template")
      );
      const filteredResources = resources?.filter(
        (resource) =>
          !(
            resource?.appType?.toLowerCase() === "api" ||
            resource?.info?.appType?.toLowerCase() === "api"
          ) &&
          !(
            resource?.appType?.toLowerCase() === "workflow" ||
            !resource?.info?.appType?.toLowerCase() === "workflow"
          ) &&
          !resource?.source?.toLowerCase().includes("template")
      );

      return {
        resources: filteredResources,
        workflows,
        apis,
        templates: [{ id: "noOption", title: "No Template" }, ...templates],
      };
    }
  };

  const getExtractors = async () => {
    try {
      const headers = await checkAuth();
      const result = await getExtractorsBriefly(headers);
      return result;
    } catch (error) {
      console.log("error", error);
      throw new Error(error);
    }
  };

  const {
    data: organizedResources,
    error: getOrganizedResourcesError,
    isLoading: getOrganizedResourcesLoading,
  } = useSWR(`/resources`, getAllResourcesAndFindTypes, {
    revalidateOnFocus: false,
  });

  const {
    data: extractors,
    error: getExtractorsError,
    isLoading: getExtractorsLoading,
  } = useSWR("/extractors", getExtractors, {
    revalidateOnFocus: false,
  });

  let StorageChatService = "";
  if (localStorage.getItem("ChatService") && !StorageChatService) {
    StorageChatService = JSON.parse(localStorage.getItem("ChatService"));
  }

  let StorageTokenSliderValue = 0;
  let DefaultTokenSliderValue = 0;

  if (
    localStorage.getItem("DefaultToken") &&
    StorageTokenSliderValue != localStorage.getItem("DefaultToken")
  ) {
    StorageTokenSliderValue = localStorage.getItem("DefaultToken");
  }
  if (StorageTokenSliderValue != DefaultTokenSliderValue) {
    DefaultTokenSliderValue = JSON.parse(localStorage.getItem("DefaultToken"));
  }

  if (DefaultTokenSliderValue > 3000 || DefaultTokenSliderValue < 50) {
    DefaultTokenSliderValue = 500;
  }

  localStorage.setItem("DefaultTab", "Chat");

  // states
  const [selectedConversationType, setSelectedConversationType] = useState(
    conversationTypes[0]
  );
  const [selectedConversationCount, setSelectedConversationCount] = useState(
    conversationCounts[0]
  );
  const [selectedExtractor, setSelectedExtractor] = useState(null);
  const [WasError, setWasError] = useState(false);
  const [TokenSliderValue, setTokenSliderValue] = useState(
    DefaultTokenSliderValue
  );
  const [loading, setLoading] = useState(false);
  const [FinalLoading, setFinalLoading] = useState(false);
  const [TeamEmail, setTeamEmail] = useState("");
  const [UpbrainsToken, setUpbrainsToken] = useState("");
  const [pat, setPat] = useState("DEFAULT TOKEN");
  const [ConversationID, setConversationID] = useState("");
  const [NumberOfPrompts, setNumberOfPrompts] = useState(1);
  const [Prompt1, setPrompt1] = useState("");
  const [Prompt2, setPrompt2] = useState("");
  const [Prompt2Default, setPrompt2Default] = useState("");
  const [Response1, setResponse1] = useState("");
  const [Response2, setResponse2] = useState("");
  const [InboxActionInProgress, setInboxActionInProgress] = useState(false);
  const [InboxActionMessage, setInboxActionMessage] = useState("");
  const [Username2, setUsername2] = useState("");
  const [DisableSubmit2, setDisableSubmit2] = useState(false);
  const [DisableDraft1, setDisableDraft1] = useState(false);
  const [DisableDraft2, setDisableDraft2] = useState(false);
  const [loadingDraftReply, setLoadingDraftReply] = useState(false);
  const [loadingDraftReply2, setLoadingDraftReply2] = useState(false);
  const [DisableReply1, setDisableReply1] = useState(false);
  const [DisableReply2, setDisableReply2] = useState(false);
  const [RedError, setRedError] = useState(true);
  const [DraftRedError, setDraftRedError] = useState(true);
  const [DraftReplyError1, setDraftReplyError1] = useState("");
  const [DraftReplyError2, setDraftReplyError2] = useState("");
  const [ErrorText, setErrorText] = useState("");
  const [ErrorText1, setErrorText1] = useState("");
  const [ErrorText2, setErrorText2] = useState("");
  const [isSummarize, setIsSummarize] = useState(false);
  const [SelectedTone, setSelectedTone] = useState("Professional");
  const [ReviewMessage, setReviewMessage] = useState("");
  const [BulletItems, setBulletItems] = useState("");
  const [selectedMessageValue, setSelectedMessageValue] = useState(
    localStorage.getItem("selectedMessageValue")
      ? JSON.parse(localStorage.getItem("selectedMessageValue"))
      : summarizeSelectedValue
  );
  const [funcCalled, setFuncCalled] = useState(false);
  const [isFullConversationChecked, setIsFullConversationChecked] = useState(
    JSON.parse(localStorage.getItem("isFullConversationChecked")) || false
  );
  const [isSignatureChecked, setIsSignatureChecked] = useState(
    JSON.parse(localStorage.getItem("isSignatureChecked")) || false
  );
  const [isMsgTypeFull, setIsMsgTypeFull] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(
    localStorage.getItem("selectedLang")
      ? JSON.parse(localStorage.getItem("selectedLang"))
      : {
          id: "english-us",
          label: "English (American)",
          value: "English (American)",
        }
  );
  const [_error, setError] = useState("");
  const [urlInput, setUrlInput] = useState("");
  const [coldError, setColdError] = useState("");
  const [isEngine, setIsEngine] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResLoading, setIsResLoading] = useState(false);
  const [isDialogOpen] = useState(false);
  const [searchInputTerm, setSearchInputTerm] = useState("");
  const [hasResources, setHasResources] = useState(false);
  const [autoCompleteValue, setAutocompleteValue] = useState({});
  const [responseAssistRes, setResponseAssistRes] = useState([]);
  const [responseAssistResRaw, setResponseAssistResRaw] = useState([]);
  const [resourcesList, setResourcesList] = useState(resourceTypeTemp);
  const [draftLoaderStatus, setDraftLoaderStatus] = useState("");
  const [resAssistStatus, setResAssistStatus] = useState({
    mode: "",
    isCollapsed: true,
  });
  const [selectedRows, setSelectedRows] = useState(0);
  const [langParams, setLangParams] = useState([]);
  const [workflowObj, setWorkflowObj] = useState({});
  const [isColChecked, setIsColChecked] = useState(
    localStorage.getItem("isColChecked") &&
      Object.keys(JSON.parse(localStorage.getItem("isColChecked")))?.length
      ? JSON.parse(localStorage.getItem("isColChecked"))
      : {}
  );
  const [apiUrl, setApiUrl] = useState("");
  const [hasApiResult, setHasApiResult] = useState(false);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);
  const [isSnippets, setIsSnippets] = useState(false);
  const [hasAgents, setHasAgents] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);

  const { attachments } = useAttachmentsSWR(
    selectedConversationType,
    isZendesk,
    UpbrainsToken,
    TeamEmail
  );

  const signRef = useRef(null);
  const { getFullMessageThread } = useFullMessageThread(
    UpbrainsToken,
    TeamEmail
  );
  const { postProcessMessage } = usePostProcessMessage();
  const msgType = localStorage.getItem("last_message_type")
    ? JSON.parse(localStorage.getItem("last_message_type"))
    : "";
  const [selectedTemplate, setSelectedTemplate] = useState("");

  useEffect(() => {
    if (msgType && messageType[msgType]) {
      setIsMsgTypeFull(true);
    } else {
      setIsMsgTypeFull(false);
    }
  }, [msgType]);

  useEffect(() => {
    if (
      funcCalled &&
      (WasError ||
        loading ||
        FinalLoading ||
        InboxActionInProgress ||
        loadingDraftReply ||
        loadingDraftReply2)
    ) {
      setWasError(false);
      setLoading(false);
      setFinalLoading(false);
      setInboxActionInProgress(false);
      setLoadingDraftReply(false);
      setLoadingDraftReply2(false);
    }
  }, [funcCalled]);

  let StorageEmail = "";
  let StorageToken = "";
  let StorageAsanaPat = "";
  let StorageEmailVerified = "";
  let storageSignMessage = false;
  let conversation_id = "";
  let FrontMessageInput = false;
  const origin = localStorage.getItem("origin");
  const appGuid = localStorage.getItem("app_guid");

  let StorageTone = "";
  if (localStorage.getItem("Tone") && !StorageTone) {
    StorageTone = JSON.parse(localStorage.getItem("Tone"));
    if (!SelectedTone || SelectedTone != StorageTone)
      setSelectedTone(StorageTone);
  }

  if (context && context?.conversation) {
    conversation_id = context?.conversation?.id;

    if (
      !decryptData("conversation_id") ||
      decryptData("conversation_id") !== conversation_id
    ) {
      encryptData("conversation_id", conversation_id);
    }
    if (ConversationID != context?.conversation?.id) {
      setConversationID(context?.conversation?.id);
      localStorage.setItem("MessageIndex", 0);
    }
  }

  if (decryptData("UpbrainsToken") && !StorageToken) {
    StorageToken = decryptData("UpbrainsToken");
  }
  if (decryptData("TeamEmail") && !StorageEmail) {
    StorageEmail = decryptData("TeamEmail");
  }
  if (localStorage.getItem("AsanaPat") && !StorageAsanaPat) {
    StorageAsanaPat = JSON.parse(localStorage.getItem("AsanaPat"));
  }
  if (localStorage.getItem("EmailVerified") && !StorageEmailVerified) {
    StorageEmailVerified = JSON.parse(localStorage.getItem("EmailVerified"));
  }

  if (StorageEmail && !TeamEmail) {
    setTeamEmail(StorageEmail);
  } else if (StorageToken && !UpbrainsToken) {
    setUpbrainsToken(StorageToken);
  } else if ((!StorageToken && !StorageEmail) || !StorageEmailVerified) {
    if (!isDebugMode) {
      window.location.replace(
        front_plugin_url + `/setting/?app_guid=${appGuid}&origin=${origin}`
      );
    }
  }

  if (StorageAsanaPat && !pat) {
    setPat(StorageAsanaPat);
  }

  if (ReviewMessage) {
    setTimeout(() => {
      setReviewMessage("");
    }, "30000");
  }
  if (ErrorText1) {
    setTimeout(() => {
      setErrorText1("");
    }, "60000");
  }
  if (ErrorText2) {
    setTimeout(() => {
      setErrorText2("");
    }, "60000");
  }
  if (DraftReplyError1) {
    setTimeout(() => {
      setDraftReplyError1("");
    }, "60000");
  }
  if (DraftReplyError2) {
    setTimeout(() => {
      setDraftReplyError2("");
    }, "60000");
  }

  const { userIdentifier, checkAuth, postLog, checkAuthForFormData } =
    usePrimitiveFunctions(UpbrainsToken, TeamEmail);
  const { getListOfMessages } = useListOfMessages(UpbrainsToken, TeamEmail);

  const getLastMessageData = useCallback(async () => {
    try {
      const {
        lastMsg = "",
        fullMsg = "",
        lastMsgId = "",
        subject = "",
        fromName = "",
        address = "",
        type = "",
        status = "",
      } = await getListOfMessages();

      if (lastMsg) {
        localStorage.setItem("subject", JSON.stringify(subject));
        localStorage.setItem("full_message", JSON.stringify(fullMsg));
        localStorage.setItem("last_message", JSON.stringify(lastMsg));
        localStorage.setItem("MessageRecipient", JSON.stringify(fromName));
        localStorage.setItem("last_message_id", JSON.stringify(lastMsgId));
        localStorage.setItem("last_message_type", JSON.stringify(type));
        localStorage.setItem("last_message_status", JSON.stringify(status));
        encryptData("message_sender", fromName);
        encryptData("message_sender_address", fromName);

        return {
          lastMsg,
          fullMsg,
          lastMsgId,
          subject,
          fromName,
          address,
          type,
        };
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }, [getListOfMessages, context]);

  const _getLastMessageStuff = useCallback(async () => {
    if (
      context &&
      (context?.type == "singleConversation" ||
        context?.type?.toLowerCase() === "question" ||
        context?.type?.toLowerCase() === "incident" ||
        context?.type?.toLowerCase() === "problem" ||
        context?.type?.toLowerCase() === "task" ||
        context?.type?.toLowerCase() === "ticket")
    ) {
      await getLastMessageData();
    } else {
      return null;
    }
  }, [context, getLastMessageData]);
  useEffect(() => {
    _getLastMessageStuff();
  }, [ConversationID, context?.conversation?.id]);

  useEffect(() => {
    const Authorization_check = async () => {
      try {
        const raw = JSON.stringify({
          plugin_integration: integration_name,
          plugin_username: context?.teammate?.username,
          plugin_user_email: context?.teammate?.email,
          identifier: userIdentifier,
        });
        const requestOptions = {
          method: "POST",
          headers: await checkAuth(),
          body: raw,
          redirect: "follow",
        };
        const res = await fetch(
          ignite_url + END_POINTS.SERVICE_APIS.GET_PLUGIN_SETTINGS,
          requestOptions
        );
        const authorization_data = await res.json();
        if (authorization_data.status == 200) {
          if (authorization_data.result.email_verified) {
            let TeamEmail = authorization_data.result.plugin_team_email;
            let UpbrainsToken = authorization_data.result.team_creator_api_key;

            if (
              !decryptData("TeamEmail") ||
              decryptData("TeamEmail") !== TeamEmail
            ) {
              encryptData("TeamEmail", TeamEmail);
            }

            if (
              !decryptData("UpbrainsToken") ||
              decryptData("UpbrainsToken") !== UpbrainsToken
            ) {
              encryptData("UpbrainsToken", UpbrainsToken);
            }

            localStorage.setItem("EmailVerified", JSON.stringify(true));

            ls.set("AuthTimer", authorization_data.result, { ttl: 3600 * 24 });
            return;
          }
        }
        localStorage.setItem("subscription", "");
        localStorage.setItem("ChatService", "");
        localStorage.setItem("EmailVerified", "");
        encryptData("TeamEmail", "");
        encryptData("UpbrainsToken", "");
        if (!isDebugMode) {
          window.location.replace(
            front_plugin_url + `/?app_guid=${appGuid}&origin=${origin}`
          );
        }
      } catch (e) {
        postLog(
          `Failed to post the plugin settings - Authorization_check(): ${e}`
        );
        localStorage.setItem("subscription", "");
        localStorage.setItem("ChatService", "");
        localStorage.setItem("EmailVerified", "");
        encryptData("TeamEmail", "");
        encryptData("UpbrainsToken", "");
        if (!isDebugMode) {
          window.location.replace(
            front_plugin_url + `/setting/?app_guid=${appGuid}&origin=${origin}`
          );
        }
      }
    };

    if (ls.get("AuthTimer")) {
    } else {
      Authorization_check();
    }

    return;
  }, []);

  const get_data = async () => {
    try {
      setWasError(false);
      new_chat();

      if (ReviewMessage) setReviewMessage("");
      if (BulletItems) {
        setBulletItems("");
      }
    } catch (e) {
      postLog(`Failed to get list of messages - get_data(): ${e}`);
      setWasError(true);
      setRedError(true);
      setErrorText(
        "Something went wrong! If the error persists, please contact support."
      );
    }
  };

  useEffect(() => {
    const _getData = () => {
      if (UpbrainsToken || TeamEmail || isDebugMode) get_data();
    };
    return () => _getData();
  }, [ConversationID]);

  function show_component(number) {
    if (number == 2) {
      setNumberOfPrompts(2);
      setLoading(false);
    } else {
      setFinalLoading(false);
    }
  }

  function reset_error() {
    setWasError(false);
    setErrorText("");
    setErrorText1("");
    setErrorText2("");
    setDraftReplyError1("");
    setDraftReplyError2("");
  }

  function set_submit_error(number) {
    let empty_promot_msg = "Please type in a prompt.";

    let overflow_prompt_msg = "";
    let overage = false;
    let prompts_length =
      Prompt1.length + Response1.length + Prompt2.length + Response2.length;
    let prompts_tokens = Math.ceil(prompts_length / 4);
    if (prompts_tokens + TokenSliderValue > 4000) {
      overage = true;
      let overage_length =
        (TokenSliderValue * 4 + prompts_length - 4000 * 4) / 4;
      overflow_prompt_msg =
        "The prompt length + response token legnths is approximately " +
        overage_length +
        " tokens more than ChatGPT input token limit of 4000. Please shortern your prompt or reduce the response token limit and try again.";
      setLoading(false);
    }

    if (number == 2) {
      if (!Prompt1) {
        setErrorText1(empty_promot_msg);
        setLoading(false);
        setRedError(true);
        return true;
      } else if (overage) {
        setErrorText1(overflow_prompt_msg);
        setLoading(false);
        setRedError(true);
        return true;
      }
      setLoading(true);
      return false;
    } else {
      if (overage) {
        setLoading(false);
        setRedError(true);
        return true;
      }
      setDisableSubmit2(true);
      setFinalLoading(true);
      return false;
    }
  }

  function submits_chat_result_error(chat_results, number) {
    let error_message = "";

    if (chat_results.status == 401) {
      error_message =
        "Unauthorized! Please ensure you are subscribed to UpBrains AI.";
      postLog(error_message);
    } else if (chat_results.status == 403) {
      error_message = !isZendesk
        ? "OAuth authentication only allows access to shared resources. Please update your Front token in UpBrains portal and update your personal preferences to allow access to personal resources."
        : chat_results.error;
    } else if (chat_results.status == 503) {
      error_message = chat_results.error;
      postLog(error_message);
    } else {
      error_message =
        "Something went wrong! Please try again. If the error persists, please contact Support. Error Code: cp000x" +
        number;
    }
    postLog(
      `Failed to submit the chat - submits_chat_result_error(): ${error_message}`
    );
    setRedError(true);
    setWasError(true);

    if (number == 2) {
      setLoading(false);
      setErrorText1(error_message);
    } else {
      setFinalLoading(false);
      setErrorText2(error_message);
    }
  }

  function catch_error(e, number) {
    setWasError(true);
    setRedError(true);

    let error_message = "";
    if (e.status == 403) {
      error_message = !isZendesk
        ? "OAuth authentication only allows access to shared resources. Please update your Front token in UpBrains portal and update your personal preferences to allow access to personal resources."
        : e.error;
    } else if (e.status == 503)
      error_message =
        "ChatGPT service is busy and response is taking too long. Please try later.";
    else if (e.status == 401)
      error_message =
        "Unauthorized! Please ensure you are subscribed to UpBrains AI.";
    else
      error_message =
        "Something went wrong! If the issue persists, please contact Support.";

    if (number == 2) {
      setErrorText1(error_message);
      setLoading(false);
    } else {
      setFinalLoading(false);
    }
  }

  function fill_components_from_history(chat_history, quick_command) {
    if (chat_history[0]) {
      if (quick_command !== "Summarize") {
        show_component(2);
      }
      let text = chat_history[0].response.trim();
      text = postProcessMessage(text);
      setResponse1(text);
    }

    if (chat_history[1]) {
      let text = chat_history[1].response.trim();
      text = postProcessMessage(text);
      setResponse2(text);

      setPrompt2(chat_history[1].prompt);
      setPrompt2Default(chat_history[1].prompt);
      setUsername2(context?.teammate.name);
      setDisableSubmit2(true);
    }
  }

  function get_latest_promot() {
    let last_prompt = "";

    if (Prompt2) {
      last_prompt = Prompt2;
    } else if (Prompt1) {
      last_prompt = Prompt1;
    }
    return last_prompt;
  }

  async function submit_data(
    number,
    prompt = "",
    get_last_message_from_thread = false,
    full_last_message = "",
    quick_command = ""
  ) {
    try {
      reset_error();
      let is_private = false;
      if (!quick_command || quick_command == "") {
        purify_and_check_tokens(number);
      }
      let validation_error = false;
      if (!quick_command || quick_command == "") {
        validation_error = set_submit_error(number);
        if (validation_error) return;
      } else {
        setLoading(true);
      }
      var raw = null;

      let input_prompt = "";

      let one_prompt = "";
      let chat_history_array = [];
      if (localStorage.getItem("chat_history")) {
        chat_history_array = JSON.parse(localStorage.getItem("chat_history"));
      }

      let result = {};

      if (quick_command === "Summarize") {
        raw = JSON.stringify({
          conversation_id: String(conversation_id),
          integration_name: String(integration_name),
          max_response_tokens: Number(TokenSliderValue),
          mode: JSON.parse(localStorage.getItem("selectedMessageValue"))?.id,
        });

        var requestOptions = {
          method: "POST",
          headers: await checkAuth(),
          body: raw,
          redirect: "follow",
        };

        result = await fetch(
          chat_url + END_POINTS.API.SUMMARIZE,
          requestOptions
        );

        if (!result?.ok && result?.status === 401) logoutUser();

        let chat_results = await result.json();

        if (chat_results.status == 200) {
          localStorage.setItem(
            "chat_history",
            JSON.stringify(chat_results?.summary)
          );

          let text = chat_results?.summary.trim();
          text = postProcessMessage(text);
          setResponse1(text);
          return chat_results?.summary;
        } else submits_chat_result_error(chat_results, number);
      } else {
        prompt = shortenMessage(prompt, quick_command);
        prompt != ""
          ? (input_prompt = prompt)
          : (input_prompt = get_latest_promot());
        raw = JSON.stringify({
          prompt: input_prompt,
          chat_history: chat_history_array,
          conversation_id: conversation_id,
          max_response_tokens: TokenSliderValue,
          plugin_user_id: context?.teammate.username,
          plugin_user_name: context?.teammate.name,
          plugin_user_email: context?.teammate.email,
          integration_name: integration_name,
          is_private: is_private,
          extract_last_message: get_last_message_from_thread,
          full_message: full_last_message,
          ...(!!(selectedTemplate && selectedTemplate.id !== "noOption") && {
            response_template_resource_id: selectedTemplate?.id,
          }),
        });

        var requestOptions = {
          method: "POST",
          headers: await checkAuth(),
          body: raw,
          redirect: "follow",
        };

        result = await fetch(
          ignite_url + END_POINTS.SERVICE_APIS.CHAT,
          requestOptions
        );

        let chat_results = await result.json();

        if (chat_results.status == 200) {
          localStorage.setItem(
            "chat_history",
            JSON.stringify(chat_results.payload.chat_history)
          );
          fill_components_from_history(
            chat_results.payload.chat_history,
            quick_command
          );
          let chat_history_length = chat_results.payload.chat_history.length;
          return chat_results.payload.chat_history[chat_history_length - 1]
            .response;
        } else submits_chat_result_error(chat_results, number);
      }
      return "";
    } catch (e) {
      postLog(`Failed to post the chat - submit_data(): ${e}`);
      catch_error(e, number);
    }
  }

  function valueText(value) {
    return `${value} Tokens`;
  }

  function shortenMessage(message, quick_command) {
    let shortened_message = message;
    const enc = getEncoding("cl100k_base");
    let max_allowed_tokens = maxPromptTokens;
    quick_command == "Summarize"
      ? (max_allowed_tokens = maxModelTokens)
      : (max_allowed_tokens = maxPromptTokens);

    const tokens = enc.encode(message);
    if (tokens.length + TokenSliderValue > max_allowed_tokens) {
      let shortened_tokens = tokens.slice(
        0,
        max_allowed_tokens - TokenSliderValue
      );
      shortened_message = enc.decode(shortened_tokens);
    }

    return shortened_message;
  }

  async function getLastMessage(full_conversation = false, draft = false) {
    try {
      var raw = null;

      raw = JSON.stringify({});

      var requestOptions = {
        method: "GET",
        headers: await checkAuth(),
        redirect: "follow",
      };

      let fullconversation_request = "&full=true";
      let url = "";

      if (!draft) {
        url =
          ignite_url +
          END_POINTS.SERVICE_APIS.LAST_MESSAGE +
          "?conversation_id=" +
          String(context?.conversation?.id);
        if (full_conversation) url = url + fullconversation_request;
      } else if (draft) {
        url =
          ignite_url +
          END_POINTS.SERVICE_APIS.LAST_DRAFT +
          "?conversation_id=" +
          String(context?.conversation?.id);
      }

      let message = "";
      let conv_id, message_id, version;

      let result = await fetch(url, requestOptions);
      let last_message_results = await result.json();

      if (last_message_results.status == 200) {
        if (!draft) {
          if (full_conversation)
            message = String(last_message_results.full_last_message);
          else message = String(last_message_results.last_message);
          message_id = String(last_message_results.message_id);
        } else if (draft) {
          message = String(last_message_results.last_draft);
          message_id = String(last_message_results.message_id);
          version = String(last_message_results.version);
          conv_id = String(last_message_results.conversation_id);
        }

        let msg_length = message.length;
        let msg_length_tokens = Math.ceil(msg_length / 4);
        if (msg_length_tokens + TokenSliderValue > 4000) {
          let last_token_index = 4000 - TokenSliderValue;
          let last_char_index = last_token_index * 4;
          message = message.slice(0, last_char_index);
          setErrorText1(
            "WARNING: The prompt length + response token length is more than ChatGPT input token limit of 4000 tokens. The prompt text is truncated to meet ChatGPT input limit. Please review and submit."
          );
          setLoading(false);
        }
      } else if (last_message_results.status == 401) {
        message = "401";
      } else if (last_message_results.status == 403) {
        message = "403";
      } else if (last_message_results.status == 404) {
        message = "404";
      } else {
        message = "500";
      }

      if (!draft) return { lastMsg: message, lastMsgId: message_id };
      else
        return {
          lastMsg: message,
          convId: conv_id,
          lastMsgId: message_id,
          version,
        };
    } catch (e) {
      postLog(
        `Failed to get the last message of last draft - getLastMessage(): ${e}`
      );
      setErrorText1(
        "Something went wrong. Please refresh your page and try again. If the error persists, please contact support. "
      );
      setLoading(false);
    }
  }

  function cleanup_and_get_last_message(
    MessageBody,
    get_very_last_message = true
  ) {
    const body = extractLastMessageFromHTMLBody(
      MessageBody,
      get_very_last_message,
      isMsgTypeFull || isFullConversationChecked,
      isSignatureChecked
    );
    MessageBody = getTextFromHTMLstr(body, true);
    return MessageBody;
  }
  const convertBulletPointsToPrompt = (bulletPoints) => {
    let EditedBulletItems = bulletPoints;
    for (let i = 0; i < 3; i++) {
      if (EditedBulletItems.endsWith("\n")) {
        EditedBulletItems = EditedBulletItems.slice(0, -1);
      }
      if (EditedBulletItems.endsWith("-- ")) {
        EditedBulletItems = EditedBulletItems.slice(0, -3);
      }
    }
    return EditedBulletItems;
  };
  async function generatePrompt(promptOption) {
    const hasTemplate = selectedTemplate;
    const hasPoints = BulletItems.length >= 12;
    try {
      setFuncCalled(false);

      let lastMsg;
      if (JSON.parse(localStorage.getItem("isFullConversationChecked"))) {
        if (
          messageType[JSON.parse(localStorage.getItem("last_message_type"))]
        ) {
          const { response } = await getFullMessageThread(
            JSON.parse(localStorage.getItem("selectedMessageValue"))?.id,
            JSON.parse(localStorage.getItem("DefaultToken")) || 500
          );
          lastMsg = response;
        } else {
          lastMsg = JSON.parse(localStorage.getItem("full_message"));
        }
      } else {
        lastMsg = JSON.parse(localStorage.getItem("last_message"));
      }

      let fromName = decryptData("message_sender");

      const language_statement =
        JSON.parse(localStorage.getItem("isLanguageChecked")) &&
        selectLanguage.id !== "auto-detect"
          ? `Create the response in ${selectLanguage.value} language and use the word spellings in this language. `
          : "If the message is non-English, respond in the language of the message. ";
      const response_limit =
        "Respond in " +
        TokenSliderValue +
        " tokens or less. Do not include a subject for the response. \n\n";
      localStorage.setItem(
        "MessageIndex",
        localStorage.getItem("ChatListLength")
      );

      FrontMessageInput = true;
      new_chat();

      if (promptOption == "Generate") {
        setIsSummarize(false);
        setInboxActionMessage(
          "ChatGPT is working... Please wait. If the response is not satisfactory, update your points above and re-generate."
        );
        setInboxActionInProgress(true);
        setLoadingDraftReply(true);
        setLoading(true);

        if (lastMsg) {
          lastMsg = cleanup_and_get_last_message(lastMsg);
        }

        if (
          lastMsg == "" ||
          lastMsg == "500" ||
          lastMsg == "401" ||
          lastMsg == "403" ||
          lastMsg == "503" ||
          lastMsg == "409"
        ) {
          let error = "";
          if (lastMsg == "") error = "The last message is empty";
          else error = showErrorMessage(lastMsg);
          setErrorText1(error);
          setLoading(false);
          setLoadingDraftReply(false);
          setInboxActionMessage("");
          setInboxActionInProgress(false);
          return;
        }

        let placeholder = false;

        const commonPartOfPrompt =
          Tonify_prompt(SelectedTone) +
          language_statement +
          response_limit +
          `My name is ${context?.teammate?.name}. This message is sent by ${fromName}.\n`;

        const promptBulletPoints = hasPoints
          ? `Points:\n${convertBulletPointsToPrompt(BulletItems)}\n\n`
          : "";

        let prompt = "";

        if (hasTemplate) {
          prompt =
            "Use the following Response Template to draft a response to the following Message. " +
            (hasPoints
              ? "In the draft response, incorporate the following Points. "
              : "") +
            commonPartOfPrompt +
            "From the Response Template, do not change the email addresses and the name of the companies. Only fill placeholders that are encapsulated between [ and ].\n\n" +
            (hasPoints ? promptBulletPoints : "") +
            "Message:\n";
        } else {
          prompt =
            (hasPoints
              ? "Please use the following Points to draft a response to the following Message. "
              : "Please draft a response to the following Message. ") +
            commonPartOfPrompt +
            "\n" +
            (hasPoints ? promptBulletPoints : "") +
            "Message:\n";
        }
        let response = null;
        let get_last_message_from_thread = false;

        let prompt_placeholder =
          "Preparing a response for the following message using a " +
          SelectedTone?.toLowerCase() +
          " tone.\n\nMessage:\n";
        setPrompt1(prompt_placeholder + lastMsg);
        if (!placeholder) {
          if (isMsgTypeFull || isFullConversationChecked) {
            prompt = prompt + lastMsg;
            response = await submit_data(2, prompt, false, "", promptOption);
          } else {
            get_last_message_from_thread = true;
            response = await submit_data(
              2,
              prompt,
              get_last_message_from_thread,
              lastMsg,
              promptOption
            );
          }
          setInboxActionInProgress(false);
          setLoadingDraftReply(false);
          setLoading(false);
          if (response != "") await post_draft(response, 0, 1);
        } else {
          prompt = lastMsg;
          setPrompt1(prompt);
        }
        setInboxActionInProgress(false);
        setLoadingDraftReply(false);
        setLoading(false);
        return;
      }

      if (promptOption == "Summarize") {
        setIsSummarize(true);
        setLoading(true);
        setLoadingDraftReply(true);
        setInboxActionMessage(
          `Summarizing the ${
            isZendesk ? "ticket" : "conversation"
          } and posting it into AI Response...`
        );
        setInboxActionInProgress(true);

        await submit_data(2, prompt, undefined, undefined, promptOption);

        setLoading(false);
        setInboxActionInProgress(false);
        setLoadingDraftReply(false);
        return;
      }

      if (promptOption == "Proofread") {
        setIsSummarize(false);
        setLoading(true);
        setLoadingDraftReply(true);
        setInboxActionMessage(
          "Getting your draft, revising and posting the updated version to AI Response. You can decide to use it after review."
        );
        setInboxActionInProgress(true);
        let LastDraft = "";
        let conv_id, message_id;
        let draft_id = null;
        let last_draft_obj = null;

        draft_id = context?.conversation?.draftId;
        if (draft_id) {
          last_draft_obj = await fetchDraft(draft_id);
        }
        if (last_draft_obj && !last_draft_obj.id) {
          const {
            lastMsg = "",
            convId = "",
            lastMsgId = "",
          } = await getLastMessage(false, true);
          LastDraft = lastMsg;
          conv_id = convId;
          message_id = lastMsgId;
        } else if (last_draft_obj) {
          LastDraft = last_draft_obj.content.body;
          localStorage.setItem("MessageRecipient", last_draft_obj.to?.name);
        }

        if (!LastDraft) {
          let error =
            "There is no draft to process. Please create a draft reply or draft message first.";
          setErrorText1(String(error));
          setLoading(false);
          setLoadingDraftReply(false);
          setInboxActionMessage("");
          return;
        }
        LastDraft = cleanup_and_get_last_message(LastDraft);
        if (
          LastDraft == "500" ||
          LastDraft == "401" ||
          LastDraft == "403" ||
          LastDraft == "503" ||
          LastDraft == "409"
        ) {
          const error = showErrorMessage(LastDraft);
          setErrorText1(error);
          setLoading(false);
          setLoadingDraftReply(false);
          return;
        }

        if (LastDraft == "404") {
          LastDraft =
            "\n[PLACEHOLDER: No draft found. Please type in your message here and press Submit.]";
          prompt =
            "Pproofread and improve the following message. \n\n" +
            "Message: " +
            LastDraft;

          setPrompt1(prompt);
          setInboxActionInProgress(false);
          setLoadingDraftReply(false);
          setLoading(false);
          return;
        }

        let prompt = "";
        if (BulletItems.length < 12) {
          prompt =
            "Proofread, re-word and clean up the Draft Message below to make it polished. Keep the structure, tone, flow and the overall content of the message. " +
            Tonify_prompt(SelectedTone) +
            "If the message is non-English, keep it in the same language. " +
            response_limit +
            "Draft Message:\n";
        } else {
          let EditedBulletItems = BulletItems;
          for (let i = 0; i < 3; i++) {
            if (EditedBulletItems.endsWith("\n")) {
              EditedBulletItems = EditedBulletItems.slice(0, -1);
            }
            if (EditedBulletItems.endsWith("-- ")) {
              EditedBulletItems = EditedBulletItems.slice(0, -3);
            }
          }

          prompt =
            "Proofread, re-word and clean up the Draft Message, using the Points below, to make it polished. Keep the structure, tone, flow and the overall content of the message. " +
            Tonify_prompt(SelectedTone) +
            "If the message is non-English, keep it in the same language. " +
            response_limit +
            "Points:\n" +
            EditedBulletItems +
            "\n\n" +
            "Draft Message:\n";
        }

        let revision_placeholder =
          "Revising your draft using a " + SelectedTone + " tone.\n\nDraft:\n";
        setPrompt1(revision_placeholder + LastDraft);
        let response = null;
        if (isMsgTypeFull || isFullConversationChecked) {
          prompt = prompt + LastDraft;
          response = await submit_data(
            2,
            prompt,
            undefined,
            undefined,
            promptOption
          );
        } else {
          prompt = prompt + LastDraft;
          let get_last_message_from_thread = false;
          response = await submit_data(
            2,
            prompt,
            get_last_message_from_thread,
            undefined,
            promptOption
          );
        }
        setInboxActionInProgress(false);
        setLoadingDraftReply(false);
        return;
      } // if Generate

      if (promptOption == "Question") {
        setIsSummarize(false);
        setPrompt1("");
        return;
      } // if Generate
      FrontMessageInput = false;
    } catch (err) {
      setLoading(false);
      setInboxActionInProgress(false);
      const errorMessage = showErrorMessage(err.message);
      setErrorText1(errorMessage);
      setRedError(true);
      setLoadingDraftReply(false);
      postLog(`Failed to generate prompt - generatePrompt(): ${err}`);
    }
  }

  const handleInsertAsComment = async () => {
    try {
      setSuccessMessage(false);
      const payload = {
        conversation_id,
        comments: [],
      };
      payload.comments.push(
        `${isZendesk ? "Ticket" : "Conversation"} Summary:\n${Response1}`
      );

      const frontRequestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      const postAsCommentPayload = {
        integration_name: integration_name,
        message: `Ticket Summary:\n\n${brRemover(Response1)}`,
        ticket_id: conversation_id,
        is_public: false,
      };
      const postAsCommentRequestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: JSON.stringify(postAsCommentPayload),
        redirect: "follow",
      };

      const requestOptions = isZendesk
        ? postAsCommentRequestOptions
        : frontRequestOptions;

      const { status, msg } = await createComment(requestOptions);

      if (status != 200) throw new Error(status);

      setRedError(false);
      setErrorText1(
        `${
          isZendesk ? "Ticket" : "Conversation"
        } summary was posted as a comment successfully.`
      );
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const errorMessage = showErrorMessage(err.message);
      if (errorMessage) setErrorText1(errorMessage);
      setRedError(true);
      postLog(
        `Failed to create comment - handleInsertAsComment: ${err}`,
        errorName,
        errorCode,
        "ignite-create-comment"
      );
    }
  };

  const handleDraftClicked = async (value) => {
    try {
      const lastMsg = JSON.parse(localStorage.getItem("last_message"));
      const lastMsgId = JSON.parse(localStorage.getItem("last_message_id"));
      if (lastMsg && lastMsgId) {
        await generatePrompt(value);
      } else {
        setLoading(false);
        setLoadingDraftReply(false);
        setErrorText1(
          "The message is empty or folded. If folded, please click on the last message to expand it."
        );
      }
    } catch (err) {
      postLog(`Failed to handle draft - handleDraftClicked(): ${err}`);
    }
  };

  const post_draft = useCallback(async (text, index, number) => {
    try {
      setSuccessMessage(false);
      if (!text) {
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1("The Response field is empty!");
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2("The Response field is empty!");
          setLoadingDraftReply2(false);
        }
        return;
      }
      let message_id = JSON.parse(localStorage.getItem("last_message_id"));
      var bracket_error_msg = "";
      if (bracket_error_msg != "") {
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(bracket_error_msg);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(bracket_error_msg);
          setLoadingDraftReply2(false);
        }
        return;
      }
      if (number == 1) {
        setDisableDraft1(true);
        setLoadingDraftReply(true);
        setDraftReplyError1("");
      } else if (number == 2) {
        setDisableDraft2(true);
        setLoadingDraftReply2(true);
        setDraftReplyError2("");
      }
      text = postProcessMessage(text, true);
      const createDraftOption = {
        content: {
          body: text,
          type: "html",
        },
        replyOptions: {
          type: "replyAll",
          originalMessageId: message_id,
        },
      };
      const draft = await createDraft(createDraftOption);
      if (!isZendesk && draft.content.body != text) {
        const updateDraftOption = {
          updateMode: "replace",
          content: {
            body: text,
            type: "html",
          },
        };
        const updatedDraft = await updateDraft(draft.id, updateDraftOption);
      }
      if (number == 1) {
        setDisableDraft1(false);
        setLoadingDraftReply(false);
      } else if (number == 2) {
        setDisableDraft2(false);
        setLoadingDraftReply2(false);
      }
      if (
        (draft && draft.id) ||
        (draft && draft.status && draft.status === 200)
      ) {
        let success_mgs = `Successfully posted the draft to ${
          isZendesk ? "Zendesk" : "Front"
        }!`;
        setSuccessMessage(success_mgs);
        setDraftRedError(false);
        if (number == 1) {
          setDraftReplyError1(success_mgs);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(success_mgs);
          setLoadingDraftReply2(false);
        }
      } else {
        let error = `Posting draft message to ${
          isZendesk ? "Zendesk" : "Front"
        } failed. If this is not resolved, please contact Support.`;
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(error);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(error);
          setLoadingDraftReply2(false);
        }
        return false;
      }
      return true;
    } catch (e) {
      setSuccessMessage(false);
      postLog(`Failed to post draft - post_draft(): ${e}`);
      setDraftRedError(true);
      if (number == 1) {
        setDraftReplyError1(
          "Something went wrong. Please refresh your page and try again. If the error persists, please contact support. "
        );
        setLoadingDraftReply(false);
      } else if (number == 2) {
        setDraftReplyError2(
          "Something went wrong. Please refresh your page and try again. If the error persists, please contact support. "
        );
        setLoadingDraftReply2(false);
      }
    }
  }, []);

  async function post_new_draft(text, index, number) {
    try {
      setSuccessMessage(false);
      if (!text) {
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1("The Response field is empty!");
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2("The Response field is empty!");
          setLoadingDraftReply2(false);
        }
        return;
      }

      var bracket_error_msg = "";
      if (bracket_error_msg != "") {
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(bracket_error_msg);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(bracket_error_msg);
          setLoadingDraftReply2(false);
        }
        return;
      }

      if (number == 1) {
        setDisableDraft1(true);
        setLoadingDraftReply(true);
        setDraftReplyError1("");
      } else if (number == 2) {
        setDisableDraft2(true);
        setLoadingDraftReply2(true);
        setDraftReplyError2("");
      }

      text = postProcessMessage(text, true);

      const createDraftOption = {
        content: {
          body: text,
          type: "html",
        },
      };
      const draft = await createDraft(createDraftOption);
      if (number == 1) {
        setDisableDraft1(false);
        setLoadingDraftReply(false);
      } else if (number == 2) {
        setDisableDraft2(false);
        setLoadingDraftReply2(false);
      }

      if (draft && draft.id) {
        let success_mgs = "Successfully posted the draft!";
        setDraftRedError(false);
        setSuccessMessage(success_mgs);
        if (number == 1) {
          setDraftReplyError1(success_mgs);

          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(success_mgs);
          setLoadingDraftReply2(false);
        }
      } else {
        let error =
          "Posting draft message failed. If this is not resolved, please contact Support.";
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(error);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(error);
          setLoadingDraftReply2(false);
        }
      }
    } catch (e) {
      postLog(`Failed to post new draft: ${e}`);
      setDraftRedError(true);
      if (number == 1) {
        setDraftReplyError1(
          "Something went wrong! Please contact support. Error code: x0001p1."
        );
        setLoadingDraftReply(false);
      } else if (number == 2) {
        setDraftReplyError2(
          "Something went wrong! Please contact support. Error code: x0001p2."
        );
        setLoadingDraftReply2(false);
      }
    }
  }

  async function post_reply(text, number) {
    try {
      setSuccessMessage(false);
      if (!text) {
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1("The Response field is empty!");
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2("The Response field is empty!");
          setLoadingDraftReply2(false);
        }
        return;
      }

      var opening_bracket_idx = text.indexOf("[");
      var closing_bracket_idx = text.indexOf("]");
      var text_len = text.length;
      var bracket_error_msg = "";

      if (
        (opening_bracket_idx > 0 &&
          opening_bracket_idx < 15 &&
          closing_bracket_idx > opening_bracket_idx &&
          closing_bracket_idx < 30) ||
        (opening_bracket_idx > text_len - 30 &&
          closing_bracket_idx > opening_bracket_idx)
      ) {
        var enclosing_text = text.substring(
          opening_bracket_idx,
          closing_bracket_idx + 1
        );
        bracket_error_msg =
          "Please replace the placeholder " +
          enclosing_text +
          " with a real value.";
      }

      if (bracket_error_msg != "") {
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(bracket_error_msg);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(bracket_error_msg);
          setLoadingDraftReply2(false);
        }
        return;
      }
      if (number == 1) {
        setDisableReply1(true);
        setLoadingDraftReply(true);
        setDraftReplyError1("");
      } else if (number == 2) {
        setDisableReply2(true);
        setLoadingDraftReply2(true);
        setDraftReplyError2("");
        setLoadingDraftReply2(false);
      }

      text = postProcessMessage(text, true);

      let frontresult = await listMessages();

      let results = frontresult.results;
      let results_minus1 = results[results.length - 1];
      let last_message_text = JSON.parse(
        localStorage.getItem("isFullConversationChecked")
      )
        ? JSON.parse(localStorage.getItem("full_message"))
        : JSON.parse(localStorage.getItem("last_message"));
      let raw = JSON.stringify({
        conversation_id: decryptData("conversation_id"),
        author_id: context?.teammate?.id,
        body: text,
        full_last_message: last_message_text,
      });

      const frontRequestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: raw,
        redirect: "follow",
      };

      const postAsCommentPayload = {
        integration_name: integration_name,
        message: brRemover(text),
        ticket_id: decryptData("conversation_id"),
        is_public: true,
      };
      const postAsCommentRequestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: JSON.stringify(postAsCommentPayload),
        redirect: "follow",
      };
      const requestOptions = isZendesk
        ? postAsCommentRequestOptions
        : frontRequestOptions;

      const resultAPI = isZendesk
        ? ignite_url + END_POINTS.INTEGRATION_APIS.POST_AS_COMMENT
        : ignite_url + END_POINTS.SERVICE_APIS.FRONT_REPLY;

      let result = await fetch(resultAPI, requestOptions);

      let chat_results = await result.json();
      if (number == 1) {
        setDisableReply1(false);
        setLoadingDraftReply(false);
      } else if (number == 2) {
        setDisableReply2(false);
        setLoadingDraftReply2(false);
      }

      if (chat_results.status == 200) {
        let success_mgs = "Successfully posted the reply!";
        setDraftRedError(false);
        if (number == 1) {
          setDraftReplyError1(success_mgs);
          setLoadingDraftReply(false);
          setSuccessMessage(success_mgs);
          setDisableReply1(false);
          setLoadingDraftReply(false);
        } else if (number == 2) {
          setDraftReplyError2(success_mgs);
          setLoadingDraftReply2(false);
          setSuccessMessage(success_mgs);
        }
      } else if (chat_results.status == 401 || chat_results.status == 403) {
        let error =
          "Unauthorized! Please ensure you are subscribed to UpBrains AI.";
        if (chat_results.status == 403) {
          error = !isZendesk
            ? "OAuth authentication only allows access to shared resources. Please update your Front token in UpBrains portal and update your personal preferences to allow access to personal resources."
            : chat_results.error;
        }

        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(error);
          setLoadingDraftReply(false);
          setDisableReply1(false);
        } else if (number == 2) {
          setDraftReplyError2(error);
          setLoadingDraftReply2(false);
        }
      } else {
        let error =
          "Something went wrong. If this is not resolved, please contact Support.";
        setDraftRedError(true);
        if (number == 1) {
          setDraftReplyError1(error);
          setLoadingDraftReply(false);
          setDisableReply1(false);
        } else if (number == 2) {
          setDraftReplyError2(error);
          setLoadingDraftReply2(false);
        }
      }
    } catch (e) {
      setSuccessMessage(false);
      postLog(`Failed to post reply: ${e}`);
      setDraftRedError(true);
      if (number == 1) {
        setDraftReplyError1(
          "Something went wrong! Please contact support. Error code: x0001p3."
        );
        setLoadingDraftReply(false);
        setDisableReply1(false);
      } else if (number == 2) {
        setDraftReplyError2(
          "Something went wrong! Please contact support. Error code: x0001p3."
        );
        setLoadingDraftReply2(false);
      }
    }
  }

  const brRemover = (text) => {
    return text.replace(/<br\s*\/?>|<\/br>/gi, "\n");
  };

  async function new_chat(newChatLinkClicked = false) {
    let help = "renew chat by refreshing";

    if (newChatLinkClicked) {
      localStorage.setItem("MessageIndex", 0);
    }
    localStorage.removeItem("chat_history");

    setResponse1("");
    setResponse2("");
    setPrompt1("");
    setPrompt2("");
    setPrompt2Default(
      "You may continue by asking ChatGPT to revise the answer."
    );
    setNumberOfPrompts(1);
    setUsername2("");
    setDisableSubmit2(false);
    setDraftReplyError1("");
    setDraftReplyError2("");
    setErrorText("");
    setErrorText1("");
    setErrorText2("");
    setReviewMessage("");
  }

  function purify_and_check_tokens(number) {
    if (number == 2) {
      setPrompt1(DOMPurify.sanitize(Prompt1.replace(/<br\s*\/?>/gm, " \n")));
    } else {
      setResponse1(
        DOMPurify.sanitize(Response1.replace(/<br\s*\/?>/gm, " \n"))
      );
      setResponse2(
        DOMPurify.sanitize(Response2.replace(/<br\s*\/?>/gm, " \n"))
      );
    }
  }

  function Tonify_prompt(tone) {
    const help = "adds tone to the message";
    let tone_statement = "";
    if (tone) {
      if (tone == "Professional") {
        tone_statement =
          "Use a professional voice and tone. " +
          "Use industry-specific language and terminology. ";
      } else if (tone == "Conversational") {
        tone_statement =
          "Use a conversational voice and tone. Imagine you're talking " +
          "to a friend and use natural language and phrasing. ";
      } else if (tone == "Humorous") {
        tone_statement =
          "Use a humorous voice and tone, include jokes, and write " +
          "with irony when appropriate. ";
      } else if (tone == "Empathic") {
        tone_statement = "Use an empathetic voice and tone. ";
      } else if (tone == "Academic") {
        tone_statement =
          "Use a formal and academic tone, advanced vocabulary and grammar," +
          " and provide a thorough analysis of the subject matter. Explain " +
          "complex concepts clearly and use examples from various fields. " +
          "Present counterarguments objectively. ";
      } else if (tone == "Simple") {
        tone_statement =
          "Use a simple language, break down complex concepts into frameworks" +
          " or models, and provide practical takeaways. ";
      } else if (tone == "Creative") {
        tone_statement =
          "Use a vivid language to create imagery and atmosphere. Use metaphors," +
          " and personification. ";
      }
    }
    return tone_statement;
  }

  const handleSelectMessageValue = ({ id, label, value }) => {
    setSelectedMessageValue({
      id,
      label,
      value,
    });
    localStorage.setItem(
      "selectedMessageValue",
      JSON.stringify({ id, label, value })
    );
  };

  const handleSelectedTone = (item) => {
    setSelectedTone(item);
    setPrompt1("");
    localStorage.setItem("Tone", JSON.stringify(item));
  };

  const handleSelectedLanguage = ({ id, label, value }) => {
    setSelectLanguage({
      id,
      label,
      value,
    });
    localStorage.setItem("selectedLang", JSON.stringify({ id, label, value }));
  };

  const handleChangeBulletItems = (value) => {
    setBulletItems(value);
  };

  const handleChangeTokenSlider = (evt) => {
    setTokenSliderValue(evt.target.value);
    localStorage.setItem("DefaultToken", JSON.stringify(evt.target.value));
  };

  const handleChangeFullConversation = (value) => {
    setIsFullConversationChecked(value);
    localStorage.setItem("isFullConversationChecked", value);
  };

  const handleChangeSignature = (value) => {
    setIsSignatureChecked(value);
    localStorage.setItem("isSignatureChecked", isSignatureChecked);
  };
  const handleSummarization = async () => {
    if (selectedConversationType?.id === "all") {
      try {
        setIsSummarize(true);
        setLoading(true);
        setLoadingDraftReply(true);
        setInboxActionMessage(
          "Summarizing the conversation and posting it into AI Response..."
        );
        setInboxActionInProgress(true);

        const headers = await checkAuth();
        const messageSenderAddress = isZendesk
          ? context?.conversation?.senderEmail
          : context?.conversation?.recipient.handle;
        const body = {
          integration_name: isZendesk ? "Zendesk" : "Front",
          target_email: messageSenderAddress,
          conversation_count:
            selectedConversationCount?.value || conversationCounts[0]?.value,
        };
        const requestOptions = {
          method: "POST",
          body: JSON.stringify(body),
          headers,
          redirect: "follow",
        };
        const url = chat_url + "/api/summarize-conversations";
        const response = await fetch(url, requestOptions);

        ///LOGOUT USER
        if (!response?.ok && response?.status === 401) logoutUser();

        const result = await response.json();

        setLoading(false);
        setInboxActionInProgress(false);
        setLoadingDraftReply(false);
        setRedError(false);

        if (response.status === 200) {
          setResponse1(result.response);
        } else if (response.status === 400) {
          throw new Error("No conversation found by this sender");
        } else {
          throw new Error("Something went wrong. Please try again later.");
        }
      } catch (error) {
        console.log("error", error);
        setErrorText1(error.message);
        setLoading(false);
        setLoadingDraftReply(false);
        setInboxActionMessage("");
        setInboxActionInProgress(false);
        setRedError(true);
      }
    } else {
      await handleDraftClicked("Summarize");
    }
  };

  const handleGeneratedDraftResponse = async () => {
    await handleDraftClicked("Generate");
  };

  const handleResponseAction = useCallback(async (value, searchType) => {
    try {
      setIsLoading(true);
      setDraftLoaderStatus("Looking for responses in your resources...");
      resetEveryThing();
      setResponseAssistRes([]);
      setResponseAssistResRaw([]);
      const resAssistStatusTemp = { ...resAssistStatus };
      let emailText;
      if (JSON.parse(localStorage.getItem("isFullConversationChecked"))) {
        if (
          messageType[JSON.parse(localStorage.getItem("last_message_type"))]
        ) {
          const { response_object } = await getFullMessageThread(
            JSON.parse(localStorage.getItem("selectedMessageValue"))?.id,
            JSON.parse(localStorage.getItem("DefaultToken")) || 500
          );
          emailText = response_object;
        } else {
          emailText = JSON.parse(localStorage.getItem("full_message"));
        }
      } else {
        emailText = JSON.parse(localStorage.getItem("last_message"));
      }

      const body = JSON.stringify({
        email_text: emailText,
        ...(localStorage.getItem("agent_name") && {
          agent_name: JSON.parse(localStorage.getItem("agent_name")),
        }),
      });
      const requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body,
        redirect: "follow",
      };
      let emailResult;
      let emailStatus;

      if (value === "create") {
        if (emailText) {
          let hasResponseAssist = false;
          let hasCustomConfig = [];

          if (
            localStorage.getItem("agents_list") &&
            JSON.parse(localStorage.getItem("agents_list")).length
          ) {
            const {
              hasResourceResults = false,
              hasCustomResourceResults = [],
            } = await getResponseAgentsResult(value);
            hasResponseAssist = hasResourceResults;
            hasCustomConfig = hasCustomResourceResults;
          } else {
            const { hasResourceResults } = await getResponseAssistResult();
            const { hasCustomResourceResults } = await getCustomConfigResult();
            hasResponseAssist = hasResourceResults;
            hasCustomConfig = hasCustomResourceResults;
          }

          if (true) {
            let { status, result } = messageType[
              JSON.parse(localStorage.getItem("last_message_type"))
            ]
              ? await postResponseAssistChatbot(requestOptions)
              : await postResponseAssistEmail(requestOptions);

            emailResult = result;
            emailStatus = status;
            setIsLoading(false);
            setDraftLoaderStatus("");
            setIsResLoading(false);
          } else {
            setIsLoading(false);
            setDraftLoaderStatus("");
            setIsResLoading(false);
            return setColdError(
              !hasResponseAssist
                ? "No resources were found to use. Please upload resources into knowledge base in UpBrains Portal."
                : "There is no API in your configuration."
            );
          }
        }

        if (emailStatus && emailStatus != 200) throw new Error(emailStatus);

        if (!emailResult?.length) {
          setColdError("No response found");
        }

        if (emailResult?.length) {
          const answerAry = emailResult?.map((item, index) => {
            const answerObj = {};
            if (item?.question) {
              answerObj.question = item.question;
            }

            if (item?.response) {
              answerObj.response = item.response;
            }

            if (item?.source) {
              answerObj.source = item.source;
            }

            if (!item?.question && !item.source) {
              answerObj.webSearchedContent = item;
            }
            return answerObj;
          });

          setResponseAssistRes(answerAry);
        }
      }

      if (value === "search") {
        setSearchInputTerm("");
        let hasResponseAssist = false;
        let hasCustomConfig = false;
        let responseAssistResult = [];
        let customConfigResult = [];
        setIsLoading(true);

        if (
          localStorage.getItem("agents_list") &&
          JSON.parse(localStorage.getItem("agents_list")).length
        ) {
          const {
            hasResourceResults = false,
            resourceList = [],
            hasCustomResourceResults = false,
            customResourceList = [],
          } = await getResponseAgentsResult(value, searchType);
          hasResponseAssist = hasResourceResults;
          responseAssistResult = resourceList;
          hasCustomConfig = hasCustomResourceResults;
          customConfigResult = customResourceList;
        } else {
          const { hasResourceResults, resourceList } =
            await getResponseAssistResult();
          const { hasCustomResourceResults, customResourceList } =
            await getCustomConfigResult(value, "", "", searchType);
          hasResponseAssist = hasResourceResults;
          responseAssistResult = resourceList;
          hasCustomConfig = hasCustomResourceResults;
          customConfigResult = customResourceList;
        }

        setIsLoading(false);

        responseAssistResult = responseAssistResult.filter(
          (template) => !template?.source?.toLowerCase().includes("template")
        );
        if (!searchType) {
          customConfigResult = customConfigResult.filter(
            (workflow) =>
              workflow?.appType?.toLowerCase() !== "workflow" &&
              workflow?.appType?.toLowerCase() !== "api"
          );
        }

        const resourceItemsList = [
          ...(!searchType ? [...resourceTypeTemp] : []),
          ...(!!(responseAssistResult.length && !searchType)
            ? [...responseAssistResult]
            : []),
          ...(!!customConfigResult.length ? [...customConfigResult] : []),
        ];

        setResourcesList(resourceItemsList);
        return resourceItemsList;
      }
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const error = showErrorMessage(err.message);
      if (error) {
        setColdError(error);
      } else {
        setColdError(err.message);
      }
      setIsLoading(false);
      setIsResLoading(false);
      postLog(
        `handleResponseAction got an error: ${err}`,
        errorName,
        errorCode,
        "ignite-response-assist-web-search"
      );
    }
  }, []);
  const handleSearchResource = async (callback) => {
    try {
      setSuccessMsg(false);

      let responseStatus;
      let responseResult;
      resetEveryThing();
      setResponseAssistRes([]);
      setResponseAssistResRaw([]);
      setIsResLoading(true);
      if (autoCompleteValue?.title?.toLowerCase().includes("workflow")) {
        setDraftLoaderStatus("Running the workflow...");
      } else if (autoCompleteValue?.title?.toLowerCase().includes("api")) {
        setDraftLoaderStatus("Calling the API...");
      } else {
        setDraftLoaderStatus("Looking for responses in your resources...");
      }
      const requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        redirect: "follow",
      };

      if (
        autoCompleteValue?.id === "DuckDuckGo" ||
        autoCompleteValue?.id === "Google"
      ) {
        let body;
        if (autoCompleteValue?.id === "DuckDuckGo") {
          let query = "";
          if (urlInput) {
            query = `${searchInputTerm} ${urlInput}`;
          } else {
            query = searchInputTerm;
          }
          body = JSON.stringify({
            engine: autoCompleteValue?.id,
            query,
          });
        } else if (autoCompleteValue?.id === "Google") {
          let query = "";
          if (urlInput) {
            query = `${searchInputTerm} site: ${urlInput}`;
          } else {
            query = searchInputTerm;
          }
          body = JSON.stringify({
            engine: autoCompleteValue?.id,
            query,
          });
        }
        requestOptions.body = body;

        if (autoCompleteValue?.title && searchInputTerm) {
          let { status, result } = await postResponseAssistWebSearch(
            requestOptions
          );

          responseStatus = status;
          responseResult = result;
        }
      } else if (autoCompleteValue?.title.includes("customUrl")) {
        if (autoCompleteValue?.title?.toLowerCase().includes("api")) {
          const body = JSON.stringify({
            email_text: searchInputTerm,
            app_name: autoCompleteValue?.appName,
          });
          requestOptions.body = body;

          let { status, result } = await postResponseAssistApiCall(
            requestOptions,
            apiCall
          );
          responseStatus = status;
          responseResult = result;
        } else if (
          autoCompleteValue?.title?.toLowerCase().includes("workflow")
        ) {
          const formData = new FormData();
          formData.append("workflow_id", autoCompleteValue?.appName);
          Object.keys(workflowObj)?.map((key) => {
            formData.append(key, workflowObj[key]);
          });

          const requestOptionsWorkflow = {
            method: "POST",
            headers: await checkAuthForFormData(),
            body: formData,
            redirect: "follow",
          };

          let results = await postResponseAssistApiCall(
            requestOptionsWorkflow,
            apiUrl
          );

          if (results?.status && results?.status !== 200) {
            throw new Error(results.status);
          }

          if (
            autoCompleteValue &&
            autoCompleteValue.outputParams &&
            autoCompleteValue.outputParams["auto-complete-data"] &&
            results &&
            results?.response &&
            results?.response[0]?.api_result
          ) {
            delete results.response[0].api_result["auto-complete-data"];
            const todayDate = new Date();
            const twoWeeksLater = todayDate.setDate(todayDate.getDate() + 14);
            results.response[0].api_result.expiryDate = twoWeeksLater;
            localStorage.setItem(
              "workflowAutoCompleteObj",
              JSON.stringify(results.response[0].api_result)
            );
            setIsLoading(false);
            setIsResLoading(false);
            setSuccessMsg("Successfully executed the workflow");
            return;
          }
          responseResult = [results];
        }
      } else {
        if (!hasResources) {
          setIsLoading(false);
          setIsResLoading(false);
          return setColdError(
            "No resources were found to ask question. Please upload resources into knowledge base in UpBrains Portal."
          );
        }
        const body = JSON.stringify({
          query: searchInputTerm,
          ...(localStorage.getItem("agent_name") && {
            agent_name: JSON.parse(localStorage.getItem("agent_name")),
          }),
          filter: {
            ...(!!(
              autoCompleteValue?.id &&
              !autoCompleteValue?.title.includes("Folder") &&
              autoCompleteValue.id !== "All My Resources"
            ) && {
              source_name: autoCompleteValue.text,
            }),
            ...(autoCompleteValue?.title.includes("Folder") && {
              category: autoCompleteValue.text,
            }),
          },
        });
        requestOptions.body = body;

        if (searchInputTerm) {
          let { status, result } = await postResponseAssistQuery(
            requestOptions
          );
          responseStatus = status;
          responseResult = result;
        }
      }
      if (responseStatus && responseStatus != 200)
        throw new Error(responseStatus);

      if (responseResult?.length) {
        const answerAry = responseResult?.map((item, index) => {
          const answerObj = {};
          if (item?.question) {
            answerObj.question = item.question;
          }

          if (item?.response) {
            answerObj.response = item.response;
          }

          if (item?.source) {
            answerObj.source = item.source;
          }

          if (
            !item?.question &&
            !item?.answer &&
            !item?.source &&
            !item?.response?.length
          ) {
            answerObj.webSearchedContent = item;
          }
          return answerObj;
        });
        setResponseAssistRes(answerAry);
      }
      setIsLoading(false);
      setIsResLoading(false);
      setDraftLoaderStatus("");
      if (callback) {
        callback();
      }
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const error = showErrorMessage(err.message);
      if (error) {
        setColdError(error);
      } else {
        setColdError(err.message);
      }
      setIsLoading(false);
      setIsResLoading(false);
      postLog(
        `postResponseAssistQuery | postResponseAssistWebSearch got an error: ${err}`,
        errorName,
        errorCode,
        "ignite-response-assist-web-search"
      );
    }
  };

  const generatePrefix = (source) => {
    if (source?.toLowerCase() === "text") {
      return "Article";
    } else if (source?.toLowerCase() === "url") {
      return "URL";
    } else if (source?.toLowerCase() === "message") {
      return "Message";
    } else if (
      source?.toLowerCase() === "files" ||
      source?.toLowerCase() === "file"
    ) {
      return "File";
    } else if (source?.toLowerCase() === "template") {
      return "Template";
    }
  };

  const getResponseAssistResult = useCallback(
    async (user_resources, isAgent, folderName) => {
      try {
        let resourceResult = user_resources;

        if (!isAgent) {
          const requestOptions = {
            method: "GET",
            headers: await checkAuth(),
            redirect: "follow",
          };
          const { status, user_resources: responseApiRes } =
            await getResponseAssist(requestOptions);

          if (status === 401) logoutUser();

          resourceResult = responseApiRes;
        }

        if (!resourceResult?.length) {
          setHasResources(false);
        } else {
          setHasResources(true);
        }

        let resourceList = resourceResult.map(
          ({ id, source, source_id, category }) => ({
            id: id,
            title: category
              ? `${category} - ${generatePrefix(source)} - ${source_id}`
              : `${generatePrefix(source)} - ${source_id}`,
            text: source_id,
            source: source,
            category: category,
          })
        );

        if (folderName) {
          resourceList = [
            ...[
              {
                id: folderName,
                title: `Folder - ${folderName}`,
                text: folderName,
              },
            ],
            ...resourceList,
          ];
        }

        return {
          hasResourceResults: !!resourceResult?.length,
          resourceList,
        };
      } catch (e) {}
    },
    []
  );

  const getCustomConfigResult = useCallback(
    async (step, results, isAgent, searchType) => {
      try {
        let customResult = results;

        if (!isAgent) {
          const requestOptions = {
            method: "GET",
            headers: await checkAuth(),
            redirect: "follow",
          };

          const { status, results: apiConfigRes } = await getCustomConfig(
            requestOptions
          );

          if (status === 401) logoutUser();
          else if (status != 200) throw new Error(status);
          customResult = apiConfigRes;
        }

        if (
          step === "search" &&
          (!localStorage.getItem("isWorkflowLoading") ||
            !JSON.parse(localStorage.getItem("isWorkflowLoading"))) &&
          (!localStorage.getItem("workflowAutoCompleteObj") ||
            !Object.keys(
              JSON.parse(localStorage.getItem("workflowAutoCompleteObj"))
            ).length)
        ) {
          customResult?.map(
            async ({
              "auto-complete-workflow": autoCompleteWorkflow,
              input_parameters,
              output_parameters,
              app_name,
              app_url,
              api_url,
              name,
            }) => {
              if (
                autoCompleteWorkflow ||
                (input_parameters &&
                  !Object.keys(input_parameters).length &&
                  output_parameters["auto-complete-data"])
              ) {
                setIsLoading(true);
                setIsWorkflowLoading(true);
                localStorage.setItem("isWorkflowLoading", true);
                setDraftLoaderStatus("Fetching the data … please wait");
                const formData = new FormData();
                formData.append(
                  "workflow_id",
                  autoCompleteWorkflow || app_name || name
                );

                const requestOptionsWorkflow = {
                  method: "POST",
                  headers: await checkAuthForFormData(),
                  body: formData,
                  redirect: "follow",
                };

                const results = await postResponseAssistApiCall(
                  requestOptionsWorkflow,
                  app_url || api_url
                );

                if (
                  results &&
                  results?.response &&
                  results?.response[0]?.api_result
                ) {
                  const apiResultObj = {};
                  Object.keys(results.response[0].api_result)
                    ?.filter((item) => item !== "auto-complete-data")
                    ?.map((item) => {
                      if (
                        (apiResultObj[item] =
                          results.response[0].api_result[item])
                      ) {
                        apiResultObj[item] = results.response[0].api_result[
                          item
                        ]?.sort((a, b) => {
                          if (a.value < b.value) {
                            return -1;
                          }
                          if (a.value > b.value) {
                            return 1;
                          }
                          return 0;
                        });
                      }
                    });

                  const todayDate = new Date();
                  const twoWeeksLater = todayDate.setDate(
                    todayDate.getDate() + 14
                  );
                  apiResultObj.expiryDate = twoWeeksLater;
                  localStorage.setItem(
                    "workflowAutoCompleteObj",
                    JSON.stringify(apiResultObj)
                  );
                }
                setIsLoading(false);
                setIsWorkflowLoading(false);
                setDraftLoaderStatus("");
                localStorage.setItem("isWorkflowLoading", false);
                return;
              }
            }
          );
        }

        const resourceList = customResult
          .filter(({ info, app_type }) => {
            return (
              (searchType && info?.app_type && info?.app_type === searchType) ||
              (searchType && app_type && app_type === searchType) ||
              (!searchType && customResult)
            );
          })
          .map(
            ({
              info,
              name,
              app_type,
              api_url,
              output_type,
              app_name,
              input_parameters,
              output_parameters,
            }) => ({
              id: name ? `customUrl-${name}` : `customUrl-${app_name}`,
              title: info
                ? `${info?.app_type} - ${name || app_name}`
                : `${app_type} - ${app_name}`,
              appName: name ? name : app_name,
              type: info ? info?.output_type : output_type,
              appType: info ? info?.app_type : app_type,
              inputParams: input_parameters,
              outputParams: output_parameters,
              appUrl: info ? info?.api_url : api_url,
            })
          );

        return {
          hasCustomResourceResults: !!customResult.length,
          customResourceList: resourceList,
        };
      } catch (e) {}
    },
    []
  );

  const getResponseAgentsResult = useCallback(async (step, searchType) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: await checkAuth(),
        redirect: "follow",
      };

      const detailed =
        localStorage.getItem("agents_list") &&
        JSON.parse(localStorage.getItem("agents_list")).length
          ? "True"
          : "False";

      const { status, results } = await getResponseAgents(
        requestOptions,
        detailed,
        localStorage.getItem("agent_name")
          ? JSON.parse(localStorage.getItem("agent_name"))
          : ""
      );

      if (status === 401) logoutUser();
      else if (status !== 200) throw new Error(status);
      if (results?.agents_config?.length) {
        const listOfAgents = results.agents_config.map((item, index) => ({
          id: Object.keys(item)[0],
          title: Object.keys(item)[0],
          text: Object.keys(item)[0],
          index,
        }));
        localStorage.setItem("agents_list", JSON.stringify(listOfAgents));

        if (!localStorage.getItem("selected_agent")) {
          localStorage.setItem(
            "selected_agent",
            JSON.stringify(listOfAgents[0])
          );
        }
        if (
          localStorage.getItem("agents_list") &&
          JSON.parse(localStorage.getItem("agents_list")).length &&
          localStorage.getItem("selected_agent")
        ) {
          setHasAgents(true);
          setAgentName(
            results?.agents_config[
              JSON.parse(localStorage.getItem("selected_agent"))?.index
            ][JSON.parse(localStorage.getItem("selected_agent"))?.id]
              ?.agent_name
          );
          localStorage.setItem(
            "agent_name",
            JSON.stringify(
              results.agents_config[
                JSON.parse(localStorage.getItem("selected_agent"))?.index
              ][JSON.parse(localStorage.getItem("selected_agent"))?.id]
                ?.agent_name
            )
          );

          const listOfFolders = results.agents_config[
            JSON.parse(localStorage.getItem("selected_agent"))?.index
          ][
            JSON.parse(localStorage.getItem("selected_agent"))?.id
          ]?.folders?.map((item) => ({
            id: item,
            title: item,
            text: item,
          }));
          localStorage.setItem(
            "list_of_folders",
            JSON.stringify(listOfFolders)
          );

          const { hasResourceResults, resourceList } =
            await getResponseAssistResult(
              results.agents_config[
                JSON.parse(localStorage.getItem("selected_agent"))?.index
              ][JSON.parse(localStorage.getItem("selected_agent"))?.id]
                ?.resources,
              true,
              listOfFolders.length
                ? JSON.parse(localStorage.getItem("selected_agent"))?.id
                : ""
            );

          const { hasCustomResourceResults, customResourceList } =
            await getCustomConfigResult(
              step,
              results.agents_config[
                JSON.parse(localStorage.getItem("selected_agent"))?.index
              ][JSON.parse(localStorage.getItem("selected_agent"))?.id]
                ?.apis_workflows,
              true,
              searchType
            );

          return {
            hasResourceResults,
            resourceList,
            hasCustomResourceResults,
            customResourceList,
          };
        }

        setHasAgents(false);
        return {
          hasResourceResults: false,
          resourceList: [],
          hasCustomResourceResults: false,
          customResourceList: [],
        };
      }

      return {
        hasResourceResults: false,
        resourceList: [],
        hasCustomResourceResults: false,
        customResourceList: [],
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const handleAddToPoint = (e, question, response, type) => {
    if (type === "web_search") {
      e.stopPropagation();
      setBulletItems(
        (prevState) =>
          `${prevState}${
            question ? `Question: ${question}\n` : ""
          }Response: ${clearFirstWord(response)}\n`
      );
    } else if (type === "api_result") {
      e.stopPropagation();

      let selectedList = [];

      if (isColChecked[autoCompleteValue?.title]) {
        selectedList = Object.keys(
          isColChecked[autoCompleteValue?.title]
        ).filter((item) => isColChecked[autoCompleteValue?.title][item]);
      }

      let integratedStr = "";
      selectedList?.map((item) => {
        if (
          response[0][type][selectedRows][item] ||
          response[0][type][selectedRows][
            autoCompleteValue?.outputParams[item]?.name
          ]
        ) {
          integratedStr =
            integratedStr +
            `${autoCompleteValue?.outputParams[item]?.name}: ${
              response[0][type][selectedRows][
                autoCompleteValue?.outputParams[item]?.name
              ]
            }\n`;
        }
      });

      setBulletItems((prevState) => `${prevState}\n${integratedStr}\n`);
    } else if (type === "search_result") {
      e.stopPropagation();

      let temp = "";
      response?.map(({ api_result, search_result }) => {
        if (
          typeof api_result === "string" ||
          typeof search_result === "string"
        ) {
          temp = clearFirstWord(search_result);
        } else if (api_result && Object.keys(api_result)?.length) {
          Object.keys(api_result)?.map((item) => {
            temp = temp + `${item}: ${api_result[item]}\n`;
          });
        }
      });

      setBulletItems(
        (prevState) =>
          `${prevState}${
            question ? `Question: ${question}\n` : ""
          }Response: ${temp}\n`
      );
    }
  };

  const resetEveryThing = () => {
    setError("");
    setColdError("");
    setSuccessMsg("");
  };

  const filterQuestionsWithIDontKnow = (data) => {
    const filteredData = [];
    for (const item of data) {
      if (item?.question) {
        const question = item?.question;
        const filteredResponses = item?.response?.filter((response) =>
          !(autoCompleteValue?.appName?.toLowerCase() === "workflow") &&
          typeof response?.search_result === "string"
            ? !response?.search_result?.toLowerCase().includes("i don't know")
            : []
        );

        filteredData.push({
          question: item?.question,
          response: item?.response,
        });
        // }
      } else {
        return setResponseAssistResRaw(data);
      }
    }

    if (filteredData.length) {
      setResponseAssistResRaw(filteredData);
    } else {
      setError("No answers were found.");
    }
  };

  const handleDraftResponse = async () => {
    setIsLoading(true);
    setDraftLoaderStatus(
      `Looking for responses in your resources to draft a response with a ${SelectedTone?.toLowerCase()} tone...`
    );
    resetEveryThing();
    setResponseAssistRes([]);
    setResponseAssistResRaw([]);
    setResAssistStatus({
      mode: "",
      isCollapsed: true,
    });
    let emailText;
    if (JSON.parse(localStorage.getItem("isFullConversationChecked"))) {
      if (messageType[JSON.parse(localStorage.getItem("last_message_type"))]) {
        const { response_object } = await getFullMessageThread(
          JSON.parse(localStorage.getItem("selectedMessageValue"))?.id,
          JSON.parse(localStorage.getItem("DefaultToken")) || 500
        );
        emailText = response_object;
      } else {
        emailText = JSON.parse(localStorage.getItem("full_message"));
      }
    } else {
      emailText = JSON.parse(localStorage.getItem("last_message"));
    }

    const body = JSON.stringify({
      email_text: emailText,
      max_token: TokenSliderValue,
      sender_name: context?.teammate?.name,
      tone: SelectedTone,
      ...(localStorage.getItem("agent_name") && {
        agent_name: JSON.parse(localStorage.getItem("agent_name")),
      }),
    });
    const requestOptions = {
      method: "POST",
      headers: await checkAuth(),
      body,
      redirect: "follow",
    };
    if (emailText) {
      let hasResponseAssist = false;
      let hasCustomConfig = [];

      if (
        localStorage.getItem("agents_list") &&
        JSON.parse(localStorage.getItem("agents_list")).length
      ) {
        const { hasResourceResults = false, hasCustomResourceResults = false } =
          await getResponseAgentsResult();
        hasResponseAssist = hasResourceResults;
        hasCustomConfig = hasCustomResourceResults;
      } else {
        const { hasResourceResults } = await getResponseAssistResult();
        const { hasCustomResourceResults } = await getCustomConfigResult();
        hasResponseAssist = hasResourceResults;
        hasCustomConfig = hasCustomResourceResults;
      }

      if (hasResponseAssist || hasCustomConfig) {
        const { status, result } = messageType[
          JSON.parse(localStorage.getItem("last_message_type"))
        ]
          ? await postResponseAssistChatbotAutoResponse(requestOptions)
          : await postResponseAssistEmailAutoResponse(requestOptions);

        if (status && status != 200) {
          const error = showErrorMessage(status);
          setError(error);
          setIsLoading(false);
          setDraftLoaderStatus("");
          setIsResLoading(false);
          throw new Error(status);
        }

        const postDraftResult = await post_draft(result[0], 0, 1);
        setIsLoading(false);
        setDraftLoaderStatus("");
        setIsResLoading(false);

        if (postDraftResult) {
          setSuccessMsg(
            "Successfully generated and posted the response as a draft!"
          );
          setIsLoading(false);
          setDraftLoaderStatus("");
          setIsResLoading(false);
        } else {
          const error = showErrorMessage(postDraftResult);
          setError(error);
          setIsLoading(false);
          setDraftLoaderStatus("");
          setIsResLoading(false);
          return undefined;
        }
      } else {
        setIsLoading(false);
        setDraftLoaderStatus("");
        setIsResLoading(false);
        return setColdError(
          !hasResponseAssist
            ? "No resources were found to use. Please upload resources into knowledge base in UpBrains Portal."
            : "There is no API in your configuration."
        );
      }
    } else {
      setIsLoading(false);
      setDraftLoaderStatus("");
      setIsResLoading(false);
      return setColdError(
        "The last message is not an inbound message or the message is empty."
      );
    }
  };

  const handleLastMessage = async () => {
    setError("");
    let searchInputTermTemp = searchInputTerm;
    const lastMsg = JSON.parse(localStorage.getItem("last_message"));
    if (
      !lastMsg ||
      lastMsg === undefined ||
      lastMsg === "" ||
      lastMsg === null
    ) {
      return setError(
        "The message is empty or folded. If folded, please click on the last message to expand it."
      );
    }
    if (searchInputTermTemp && searchInputTermTemp.includes("Message:")) {
      const rawLetter = removeLettersAfterWord(searchInputTermTemp, "Message:");
      searchInputTermTemp = `${rawLetter}\n${lastMsg}`;
    } else if (searchInputTermTemp.length) {
      searchInputTermTemp = `${searchInputTermTemp}\n\n${lastMsg}`;
    } else {
      searchInputTermTemp = `\n${lastMsg}`;
    }
    setSearchInputTerm(searchInputTermTemp);
  };

  const handleGetLastMessageFromCallback = async (value, searchType) => {
    setFuncCalled(true);
    setWorkflowObj({});
    setResponseAssistRes([]);
    setResponseAssistResRaw([]);
    const lastMsg = JSON.parse(localStorage.getItem("last_message"));
    const lastMsgId = JSON.parse(localStorage.getItem("last_message_id"));
    if (lastMsg && lastMsgId) {
      if (value === "auto-draft") {
        await handleDraftResponse();
      } else {
        await handleResponseAction(value, searchType);
      }
    } else if (!lastMsg && value === "search") {
      await handleResponseAction(value);
    } else if (!lastMsg && value !== "search") {
      setIsLoading(false);
      setIsResLoading(false);
      return setError(
        "The message is empty or folded. If folded, please click on the last message to expand it."
      );
    }
  };

  const handleAutoCompleteChange = () => {
    setResponseAssistResRaw([]);
  };

  useEffect(() => {
    if (responseAssistRes.length) {
      filterQuestionsWithIDontKnow(responseAssistRes);
    }
  }, [responseAssistRes]);

  useEffect(() => {
    if (_error !== "" || coldError !== "" || successMsg !== "") {
      setTimeout(() => {
        resetEveryThing();
      }, 60000);
    }
  }, [_error, coldError, successMsg]);

  useEffect(() => {
    if (isDialogOpen) {
      resetEveryThing();
      setFuncCalled(true);
      setSearchInputTerm("");
      setUrlInput("");
      setWorkflowObj({});
      setResponseAssistRes([]);
      setResponseAssistResRaw([]);
      setResAssistStatus({
        mode: "",
        isCollapsed: true,
      });
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (
      autoCompleteValue.id === "DuckDuckGo" ||
      autoCompleteValue.id === "Google" ||
      autoCompleteValue.id === "Bing"
    ) {
      setIsEngine(true);
    } else {
      setIsEngine(false);
    }
  }, [autoCompleteValue]);

  useEffect(() => {
    const langParamsObj = {};
    if (
      autoCompleteValue?.title?.toLowerCase().includes("workflow") &&
      autoCompleteValue?.outputParams
    ) {
      Object.keys(autoCompleteValue.outputParams)
        ?.sort(
          (a, b) =>
            autoCompleteValue["outputParams"][a]?.order -
            autoCompleteValue["outputParams"][b]?.order
        )
        ?.map((item) => {
          langParamsObj[autoCompleteValue.title] = {
            ...langParamsObj[autoCompleteValue.title],
            [item]: true,
          };
        });

      if (!isColChecked[autoCompleteValue?.title]) {
        setIsColChecked((prev) => ({ ...prev, ...langParamsObj }));
      }
    }
  }, [autoCompleteValue]);

  useEffect(() => {
    localStorage.setItem(
      "response-assist-search-dropdown",
      JSON.stringify(autoCompleteValue)
    );
  }, [autoCompleteValue]);

  useEffect(() => {
    if (
      autoCompleteValue?.title?.toLowerCase().includes("workflow") &&
      autoCompleteValue?.inputParams
    ) {
      const langParamsTemp = Object.keys(autoCompleteValue.inputParams)
        ?.filter((item) => autoCompleteValue["inputParams"][item]?.order !== -1)
        ?.filter((item) => item !== "auto-complete-data")
        ?.sort(
          (a, b) =>
            autoCompleteValue["inputParams"][a]?.order -
            autoCompleteValue["inputParams"][b]?.order
        )
        ?.map((item) => ({
          key: item,
          value: autoCompleteValue["inputParams"][item]?.name,
          defaultValue:
            autoCompleteValue["inputParams"][item]["default-value"] || "",
          isAutoCompleteData:
            autoCompleteValue["inputParams"][item]["autocomplete"] || false,
        }));
      setLangParams(langParamsTemp);
    }
    if (autoCompleteValue?.appUrl) setApiUrl(autoCompleteValue.appUrl);
  }, [autoCompleteValue]);

  useEffect(() => {
    if (autoCompleteValue?.title) {
      setSelectedRows(0);
      setWorkflowObj({});
      setHasApiResult(false);
    }
  }, [autoCompleteValue]);

  useEffect(() => {
    localStorage.setItem("isColChecked", JSON.stringify(isColChecked));
  }, [isColChecked]);

  useEffect(() => {
    if (
      localStorage.getItem("isWorkflowLoading") &&
      JSON.parse(localStorage.getItem("isWorkflowLoading"))
    ) {
      setIsWorkflowLoading(true);
    } else {
      setIsWorkflowLoading(false);
    }
  }, [isLoading]);

  const handleAutoDraft = () => {
    setIsSnippets(false);
    handleGetLastMessageFromCallback("auto-draft");
  };

  const handleResponseSnippets = () => {
    setIsSnippets(true);
    handleGetLastMessageFromCallback("create");
  };

  const handleSearchResources = (searchType) => {
    setIsSnippets(false);
    handleGetLastMessageFromCallback("search", searchType);
  };

  const handleUrlInput = (value) => {
    setUrlInput(value);
  };

  const handleTypeaheadChange = (key, selected) => {
    if (selected && selected.length) {
      setWorkflowObj((prev) => ({
        ...prev,
        [key]: selected[0].value,
      }));
    } else {
      delete workflowObj[key];
      setWorkflowObj((prev) => ({
        ...prev,
      }));
    }
  };

  const handleResponseAssistInputChange = (key, value) => {
    if (value) {
      setWorkflowObj((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      delete workflowObj[key];
      setWorkflowObj((prev) => ({
        ...prev,
      }));
    }
  };

  const handleResponseAssistTextChange = (value) => {
    setSearchInputTerm(value);
  };

  const handleChangeResponse1 = (value) => {
    setResponse1(value);
  };

  const handleReset = () => {
    setBulletItems("");
    setResponse1("");
    setResponseAssistResRaw([]);
    setSearchInputTerm("");
    setBulletItems("");
    setAutocompleteValue({
      id: "All My Resources",
      title: "All My Resources",
      text: "All My Resources",
    });
    setLangParams([]);
    setError("");
    setColdError("");
    setSuccessMsg("");
  };
  const features = useMemo(
    () => [
      // {
      //   id: "conversationResult",
      //   title: isZendesk ? "Ticket Brief" : "Conversation Brief",
      //   hasResponse: false,
      //   hint: isZendesk ? "Result of ticket" : "Result of conversation",
      //   isDisabled: false,
      // },
      {
        id: "ATTACHMENTS",
        title: "Attachments",
        hasResponse: false,
        hint: "Read your Attachments",
        isDisabled: !attachments?.length,
      },
      {
        id: "draftAResponse",
        title: "Draft a response",
        hasResponse: false,
        hint: "Find answers and draft a response using your resources",
        isDisabled: false,
      },
      {
        id: "autoDraft",
        title: "Auto Draft",
        hasResponse: false,
        hint: "Automatically drafts a response using your resources",
        isDisabled:
          !organizedResources?.resources?.length &&
          localStorage.getItem("isGoogleChecked"),
      },
      {
        id: "searchMyResources",
        title: "Knowledge Base",
        hasResponse: false,
        hint: "Search your resources or the Web",
        isDisabled: !organizedResources?.resources?.length,
      },
      {
        id: "Workflow",
        title: "Workflows",
        hasResponse: false,
        hint: "Run your workflows",
        isDisabled: false,
      },
      {
        id: "summarize",
        title: "Summarize",
        hasResponse: false,
        hint: isZendesk
          ? "Summarizes the ticket"
          : "Summarizes the conversation",
        isDisabled: false,
      },
      // {
      //   id: "API",
      //   title: "APIs",
      //   hasResponse: false,
      //   hint: "Call your APIs",
      //   isDisabled: !organizedResources?.apis?.length,
      // },
      {
        id: "TRANSLATE",
        title: "Translate",
        hasResponse: false,
        hint: "Translate your Attachments",
        isDisabled: false,
      },
    ],
    [organizedResources, attachments]
  );

  const addNewFeature = (feature) => {
    if (
      feature?.id &&
      feature?.title &&
      !features.find((_feature) => _feature.id === feature.id)
    ) {
      features.push(feature);
    }
  };

  const handleSelectTemplate = (value) => {
    setSelectedTemplate(value);
  };

  const handlePostFeedback = (feedback) => {
    console.log("feedback", feedback);
  };

  const handleSelectConversationType = (conversationType) => {
    setSelectedConversationType(conversationType);
  };

  const handleSelectConversationCount = (conversationCount) => {
    setSelectedConversationCount(conversationCount);
  };

  return {
    getOrganizedResourcesError,
    getOrganizedResourcesLoading,
    resources: organizedResources?.resources,
    workflows: organizedResources?.workflows,
    apis: organizedResources?.apis,
    templates: organizedResources?.templates,
    features,
    isLoading,
    isResLoading,
    isWorkflowLoading,
    _error,
    coldError,
    successMsg,
    Response1,
    SelectedTone,
    selectedMessageValue,
    selectLanguage,
    BulletItems,
    TokenSliderValue,
    DefaultTokenSliderValue,
    isFullConversationChecked,
    isSignatureChecked,
    responseAssistResRaw,
    isSnippets,
    autoCompleteValue,
    isColChecked,
    hasApiResult,
    selectedRows,
    draftLoaderStatus,
    isEngine,
    urlInput,
    resourcesList,
    langParams,
    workflowObj,
    searchInputTerm,
    setIsColChecked,
    setSelectedRows,
    setHasApiResult,
    handleAddToPoint,
    clearFirstWord,
    InboxActionInProgress,
    InboxActionMessage,
    ErrorText1,
    disableReply: DisableReply1,
    draftRedError: DraftRedError,
    draftReplyError: DraftReplyError1,
    DraftReplyError2,
    disableDraftPrimary: DisableDraft1,
    loadingDraftReply: loadingDraftReply,
    disableDraftSecondary: DisableDraft1,
    RedError,
    TeamEmail,
    UpbrainsToken,
    ConversationID,
    selectedTemplate,
    DraftReplyError1,
    handleSelectTemplate,
    postReply: () => post_reply(Response1, 1),
    postDraft: () => post_draft(Response1, 0, 1),
    postNewDraft: () => post_new_draft(Response1, 0, 1),
    handleChangeSignature,
    handleChangeFullConversation,
    valueText,
    handleChangeTokenSlider,
    handleSelectMessageValue,
    handleSelectedTone,
    handleSelectedLanguage,
    handleChangeBulletItems,
    handleSummarization,
    handleAutoDraft,
    handleResponseSnippets,
    handleGeneratedDraftResponse,
    handleUrlInput,
    handleTypeaheadChange,
    handleResponseAssistInputChange,
    handleResponseAssistTextChange,
    resetEveryThing,
    setAutocompleteValue,
    handleAutoCompleteChange,
    handleLastMessage,
    handleSearchResource,
    handleSearchResources,
    handleChangeResponse1,
    handleInsertAsComment,
    handleReset,
    handleResponseAction,
    checkAuth,
    getLastMessage,
    handlePostFeedback,
    extractors,
    addNewFeature,
    selectedExtractor,
    setSelectedExtractor,
    getExtractorsError,
    getExtractorsLoading,
    getListOfMessages,
    selectedConversationType,
    handleSelectConversationType,
    selectedConversationCount,
    handleSelectConversationCount,
  };
};

export default useChat;
