import { useCallback, useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import {
  Button,
  Checkbox,
  Paragraph,
  Select,
  SelectItem,
  Textarea,
} from "@frontapp/ui-kit";

import { isZendesk } from "../../../../constants/plugin_variables";
import useAttachmentsSWR from "../../utils/hooks/useAttachmentsSWR";
import usePrimitiveFunctions from "../../services/server/usePrimitiveFunctions";
import { usePluginContext } from "../../../../providers/contextManagement";
import showErrorMessage from "../../utils/functions/showErrorMessage";
import { date_token, languageList } from "../../constants/variables";
import { postTranslateAttachment } from "../../services/server/translate.service";
import AIResponse from "../common/AIResponse";
import createComment from "../../../../plugin-apis/createComment";
import { logoutUser } from "../../utils/functions/logoutUser";

const TranslateAttachment = ({
  UpbrainsToken,
  TeamEmail,
  selectLanguage,
  handleSelectedLanguage,
  features,
  ConversationID,
}) => {
  const [checkboxValue, setCheckboxValue] = useState({
    lastMessage: true,
    attachment: false,
  });
  const [error, setError] = useState("");
  const [commentError, setCommentError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isCommentSucceed, setIsCommentSucceed] = useState(false);
  const [attachmentType, setAttachmentType] = useState("MESSAGE");
  const [selectedAttachment, setSelectedAttachment] = useState({
    id: "",
    name: "",
    is_inline: false,
    content_type: "",
  });
  const [translatedValue, setTranslatedValue] = useState("");

  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));
  const { checkAuthForFormData, checkAuth, postLog } = usePrimitiveFunctions(
    UpbrainsToken,
    TeamEmail
  );
  const {
    attachments,
    getAttachmentsError,
    getAttachmentsLoading,
    getAttachmentsMutate,
  } = useAttachmentsSWR(
    attachmentType,
    isZendesk,
    UpbrainsToken,
    TeamEmail,
    "/workflow"
  );
  if (error || isSucceed) {
    setTimeout(() => {
      setError("");
      setIsSucceed(false);
    }, 10000);
  }

  const handleSelectAttachmentType = (type) => {
    setAttachmentType(type);
    getAttachmentsMutate();
  };

  const handlePostTranslate = useCallback(async () => {
    try {
      setError("");
      setIsLoading(true);
      setIsSucceed(false);
      setTranslatedValue("");

      const formData = new FormData();
      formData.append("target_language", selectLanguage.id);
      if (checkboxValue.lastMessage) {
        formData.append("message_text", localStorage.getItem("last_message"));
      } else {
        formData.append("integration_name", isZendesk ? "Zendesk" : "Front");
        formData.append("attachment", JSON.stringify(selectedAttachment));
      }

      const requestOptions = {
        method: "POST",
        headers: await checkAuthForFormData(),
        body: formData,
        redirect: "follow",
      };

      const { status, result } = await postTranslateAttachment(
        checkboxValue.lastMessage ? "last_message" : "attachment",
        requestOptions
      );

      // const { status, result } = {
      //   status: 200,
      //   number_of_pages_processed: 1,
      //   result:
      //     '"مرحبًا،\n\nتتساءل عن سياسة الإرجاع الخاصة بك؟\n\nوكم من الوقت يستغرق شحن منتجاتك؟\n\nشكرًا،\n\nآدم"',
      // };

      ///LOGOUT USER
      if (status === 401) logoutUser();
      else if (status !== 200) throw new Error(status);

      setIsLoading(false);
      setIsSucceed(true);

      features.find(({ id }) => id === "TRANSLATE").hasResponse = true;
      setTranslatedValue(result.slice(1, -1));

      return Promise.resolve(result);
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const error = showErrorMessage(err.message);
      setError(error);
      setIsSucceed(false);
      setIsLoading(false);
      postLog(
        `handlePostTranslate got an error: ${err}`,
        errorName,
        errorCode,
        "workflow-api"
      );
      return Promise.reject(err.message);
    }
  }, [selectedAttachment, checkAuthForFormData, postLog]);

  const handleInsertAsComment = async () => {
    try {
      setError("");
      setCommentError("");
      setIsSucceed(false);
      setIsCommentSucceed(false);
      setIsCommentLoading(true);

      const payload = {
        ...(!isZendesk && { conversation_id: ConversationID }),
        ...(!isZendesk && { comments: [] }),
        ...(isZendesk && {
          message: `Translation Result:\n\n ${translatedValue}`,
        }),
        ...(isZendesk && { ticket_id: ConversationID }),
        integration_name: isZendesk ? "Zendesk" : "Front",
        is_public: false,
      };

      if (!isZendesk) {
        payload.comments.push(`Translation Result:\n\n ${translatedValue}`);
      }

      const requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      const { status, msg } = await createComment(requestOptions);

      if (status != 200) throw new Error(status);

      setError("");
      setCommentError("");
      setIsSucceed(false);
      setIsCommentSucceed(true);
      setIsCommentLoading(false);
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const error = showErrorMessage(err.message);
      setCommentError(error);
      setIsCommentSucceed(false);
      setIsCommentLoading(false);
      postLog(
        `handleInsertAsComment got an error: ${err}`,
        errorName,
        errorCode,
        "front-comment-tag"
      );
      return Promise.reject(err.message);
    }
  };

  const brRemover = (text) => {
    return text.replace(/<br\s*\/?>|<\/br>/gi, "\n");
  };

  const handleChangeCheckbox = (value) => {
    if (value === "last_message") {
      setCheckboxValue({ lastMessage: true, attachment: false });
    } else {
      setCheckboxValue({ lastMessage: false, attachment: true });
    }
  };

  useEffect(() => {
    if (getAttachmentsError && getAttachmentsError?.message) {
      const errorMessage = showErrorMessage(getAttachmentsError.message);
      setError(errorMessage);
    }
  }, [getAttachmentsError]);

  return (
    <>
      <div className="conversion-settings-container">
        <div className="custom-form-label">What to translate:</div>
        <div className="translate-1">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={checkboxValue.lastMessage}
              onChange={() => handleChangeCheckbox("last_message")}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Last Message
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={checkboxValue.attachment}
              onChange={() => handleChangeCheckbox("attachment")}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              An Attachment
            </label>
          </div>

          <div
            className={`attachments-container p-15 ${
              !checkboxValue.attachment && "disable-element"
            }`}
          >
            <div className="translate-2">Select Your Attachments</div>
            <div className="translate-3">
              <Button
                isDisabled={getAttachmentsLoading}
                onClick={() => handleSelectAttachmentType("MESSAGE")}
                type={attachmentType === "MESSAGE" ? "primary" : "secondary"}
              >
                Last Message
              </Button>
              <Button
                isDisabled={getAttachmentsLoading}
                onClick={() => handleSelectAttachmentType("ALL")}
                type={attachmentType === "ALL" ? "primary" : "secondary"}
              >
                All
              </Button>
            </div>

            {getAttachmentsLoading && (
              <div className="translate-4">
                <BeatLoader
                  color={"#0059b3"}
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}

            <div className="w-full">
              <Select
                placeholder="Choose Attachment"
                layerRootId="story--components-select--multi"
                selectedValues={selectedAttachment.name}
                isDisabled={getAttachmentsLoading}
              >
                {attachments?.length
                  ? attachments?.map((attachment) => (
                      <SelectItem
                        key={attachment.id}
                        isSelected={selectedAttachment?.id === attachment?.id}
                        onClick={() =>
                          setSelectedAttachment({
                            id: attachment.id,
                            name: attachment.name,
                            is_inline: attachment.is_inline,
                            content_type: attachment.content_type,
                          })
                        }
                      >
                        {attachment.name}
                      </SelectItem>
                    ))
                  : null}
              </Select>
            </div>
          </div>

          <div className="form-element-container">
            <div className="custom-form-label">Target Language:</div>
            <Select
              layerRootId="story--components-select--basic"
              selectedValues={
                languageList.find(({ value }) => value === selectLanguage.value)
                  ?.value
              }
            >
              {languageList
                .filter(({ id }) => id !== "auto-detect")
                .map(({ id, label, value }, index) => (
                  <SelectItem
                    key={`${id}-${value}-${index}`}
                    onClick={() => handleSelectedLanguage({ id, label, value })}
                  >
                    {label}
                  </SelectItem>
                ))}
            </Select>
          </div>

          <div className="translate-5">
            <Button
              isDisabled={getAttachmentsLoading || isLoading}
              onClick={handlePostTranslate}
              type={"primary"}
            >
              Translate
            </Button>
          </div>
        </div>
        {isLoading && (
          <div className="translate-4">
            <BeatLoader
              color={"#0059b3"}
              loading={true}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {!!error && <Paragraph color="red">{error}</Paragraph>}
        {!!isSucceed && (
          <Paragraph color="green">
            Successfully translated the message.
          </Paragraph>
        )}

        {!!translatedValue.length && (
          <div className={"ai-response-container"}>
            <h3 className="heading">Translation Result</h3>
            <Textarea
              rows={10}
              value={translatedValue}
              onChange={setTranslatedValue}
              shouldAllowResize={true}
            />

            <div className="flex justify-end">
              <Button type="primary" onClick={handleInsertAsComment}>
                Insert as Comment
              </Button>
            </div>

            {isCommentLoading && (
              <div className="translate-4">
                <BeatLoader
                  color={"#0059b3"}
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            {!!commentError && (
              <Paragraph color="red">{commentError}</Paragraph>
            )}
            {!!isCommentSucceed && (
              <Paragraph color="green">
                Successfully inserted as a comment.
              </Paragraph>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TranslateAttachment;
