import {
  Button,
  Paragraph,
  Tooltip,
  TooltipCoordinator,
} from "@frontapp/ui-kit";
import React from "react";
import { RingLoader } from "react-spinners";
import { COLORS } from "../../constants/colors";
import { isZendesk } from "../../../../constants/plugin_variables";

const DraftAResponseActions = ({
  disableDraftPrimary,
  postDraft,
  postNewDraft,
  disableDraftSecondary,
  disableReply,
  postReply,
  draftReplyError,
  draftRedError,
  loadingDraftReply,
  onSaveAsTemplate,
}) => {
  return (
    <>
      <div className="justify-center">
        <div className="margin-bottom">
          <TooltipCoordinator
            renderTooltip={() => (
              <Tooltip>
                Posts this response as a drafts reply to the selected
                {isZendesk ? "tickets" : "conversation"}
              </Tooltip>
            )}
          >
            <Button
              size="small"
              isDisabled={disableDraftSecondary}
              onClick={postDraft}
            >
              Draft as Reply
            </Button>
          </TooltipCoordinator>
        </div>
        &nbsp;&nbsp;
        <div className={`draft-1 ${isZendesk ? "display-none" : ""}`}>
          <TooltipCoordinator
            renderTooltip={() => (
              <Tooltip>Posts this response as a new message draft</Tooltip>
            )}
          >
            <Button
              size="small"
              isDisabled={disableDraftPrimary}
              onClick={postNewDraft}
            >
              Draft as New
            </Button>
          </TooltipCoordinator>
        </div>
        &nbsp;&nbsp;
        <div className="draft-1">
          <TooltipCoordinator
            renderTooltip={() => (
              <Tooltip>
                Sends this response as a reply to the selected{" "}
                {isZendesk ? "tickets" : "conversation"}
              </Tooltip>
            )}
          >
            <Button size="small" isDisabled={disableReply} onClick={postReply}>
              Reply this Response
            </Button>
          </TooltipCoordinator>
        </div>
        &nbsp;&nbsp;
        <div className="draft-1">
          <TooltipCoordinator
            renderTooltip={() => (
              <Tooltip>Saves this response as a template</Tooltip>
            )}
          >
            <Button
              size="small"
              // isDisabled={disableReply}
              onClick={onSaveAsTemplate}
            >
              Save as a Template Response
            </Button>
          </TooltipCoordinator>
        </div>
      </div>
      <div className="spinner">
        <RingLoader
          color={COLORS.NAVY_BLUE}
          loading={loadingDraftReply}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      {!!draftReplyError && (
        <>
          <Paragraph color={draftRedError ? "red" : "green"}>
            {draftReplyError}
          </Paragraph>
          <br />
        </>
      )}
    </>
  );
};

export default DraftAResponseActions;
